import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import app from "../Firebase/Firebase";

const ShareLink = () => {
  const [linkExpireAlert, setlinkExpireAlert] = useState(false);
  const [message, setMessage] = useState("");

  const param = useParams();
  const getUrlInfoData = async () => {
    const firestore = getFirestore(app);
    const docSnap = await getDoc(doc(firestore, "invite", param.id));

    if (docSnap.exists()) {
      const date = new Date();
      const dateTimeInSeconds = date.getTime() / 1000;

      const time = await docSnap.get("create_date");
      const hourDiff = 24 * 60 * 60;
      if (docSnap && Math.abs(time.seconds - dateTimeInSeconds) > hourDiff) {
        console.log(
          Math.abs(time.seconds - dateTimeInSeconds),
          " == ",
          hourDiff
        );
        setMessage("Invitation link is expired !");
        setlinkExpireAlert(true);
        return true;
      } else {
        window.location.replace("/shareitnow/?id=" + param.id);
      }
    } else {
      setMessage("הלינק כבר היה בשימוש, בקשו מחבר שכבר בפנים שישלח לכם לינק אישי חדש");
      setlinkExpireAlert(true);
      return true;
    }
  };

  const showModal = () => {
    return (
      linkExpireAlert === true && (
        <div className="popup-3">
          <div className="popup-good popup-existing-profile "></div>
          <div className="modal d-block splash-auto-open-popup">
            <div className="modal-dialog splash-modal">
              <div className="modal-content splash-modal-content">
                <div className="modal-body">
                  <div className="modal-header term-icon"></div>
                  <h2 className="popup-title-top">
                    {message}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    getUrlInfoData();
    // console.log('i fire once');
  }, []);
  return <>{showModal()}</>;
};

export default ShareLink;
