import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import select_occupation from "../../Common/Assets/Images/select_occupation.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OCCUPATION_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

import occupation_male_1 from "../../Common/Assets/background/occupation_male_1.png";
import occupation_female_1 from "../../Common/Assets/background/occupation_female_1.png";
import occupation_male_2 from "../../Common/Assets/background/occupation_male_2.png";
import occupation_female_2 from "../../Common/Assets/background/occupation_female_2.png";

const SelectOccupation = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [occupation_options, setOccupationOptions] = useState("");

  const [show, setShow] = useState(false);

  const firestore = getFirestore(app);

  const { single_gen, single_looking_gen } = useSelector((state) => state);

  var gender =
  sid != null && sid != "" ? (single_looking_gen == 0 ? 1 : 0) : single_gen;

  const [backgroundImage, setBackgroundImage] = useState(
    gender == 0 ? occupation_male_1 : occupation_female_1
  );

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("", "", "");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("", "", "");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const occupation = docSnap.get("occupation");

      if (occupation != null) {
        backgroungForOccupation(occupation);

        setOccupationOptions(occupation);
      }
    }

    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    console.log("occupation_options", occupation_options);

    if (occupation_options != "") {
      dispatch({ type: OCCUPATION_DATA, payload: occupation_options });

      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          occupation: occupation_options,
          last_updated: serverTimestamp(),
        });
      }

      if (linkId != null && linkId != "") {
        Navigate(`/select-lifestyle?id=${linkId}`);
      } else {
        Navigate(`/select-lifestyle?sid=${sid}`);
      }
    }
  };

  const backgroungForOccupation = (occupation) => {
    if (occupation == "") {
      if (gender == 0) {
        setBackgroundImage(occupation_male_1);
      } else if (gender == 1) {
        setBackgroundImage(occupation_female_1);
      }
    } else {
      if (gender == 0) {
        setBackgroundImage(occupation_male_2);
      } else if (gender == 1) {
        setBackgroundImage(occupation_female_2);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home location occupation"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine location"
                  src={Logo}
                  alt=""
                />
              </div>

              {/* <div class="border-line">
                <div></div>
                <div class="second"></div>
                <div class="third"></div>
                <div class="forth"></div>
              </div> */}

              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>

              <div className="logo-div ">
                <p className="text-1 gender-1-text location">
                  <b>{"מה עושה במהלך היום?"}</b>
                </p>
              </div>

              <div class="select-content input-field">
                <label>
                  <input
                    type="text"
                    name="name"
                    placeholder="תחום עיסוק"
                    value={occupation_options}
                    onChange={(e) => {
                      setOccupationOptions(e.target.value);
                      backgroungForOccupation(e.target.value);
                    }}
                  />
                </label>
              </div>

              <div className="image-group1 location-btn occupation">
                <div className="button-div btn-change">
                  <button
                    className="button-accept profile-button"
                    style={
                      occupation_options != ""
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "grey" }
                    }
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectOccupation;
