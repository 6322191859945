import {
  ASSOCIATED_PROFILES,
  CLEAR_STATES,
  CLOSE_BUTTON,
  CROP_IMAGE,
  HEIGHT_DATA,
  SINGLE_GEN,
  SINGLE_LOOKING_GEN,
  AGE_DATA,
  LOCATION_DATA,
  OCCUPATION_DATA,
  LIFESTYLE_DATA,
  POLITICS_DATA,
  AGE_RANGE_DATA,
  LIFESTYLE_LOOKING_DATA,
  GEOGRAPHY_DATA,
  IMAGE_DATA,
  LCS_STATE,
  LHS_KEY,
  LRPID,
  MATCHER_ID,
  MATCH_REJECT,
  MOBLIE_NUMBER,
  PERSONANLITY_DATA,
  REJECTRESON_DATA,
  POP_UP,
  PREFILL_DATA,
  RCS_STATE,
  RHS_KEY,
  SAVE_TERMS,
  SELECT_IMAGE,
  SELECT_INDEX,
  SELECT_POP,
  SET_IMAGE,
  SINGLES_ID,
  SINGLE_DOCID,
  S_NAME,
  M_NAME,
  TERMS_POPUP,
  TIMESTEMP,
  UPLOAD_IMAGE,
  USER_GENDER,
  USER_ID,
  phoneNumberToBeVerify,
  otpConfirmationResult,
  spid,
  lrmpid,
} from "./constants";

const initialState = {
  loaded: false,
  loading: false,
  image: [
    { url: "", id: 1 },
    { url: "", id: 2 },
    { url: "", id: 3 },
  ],
  selectIndex: 0,
  imageSlect: null,
  id: -1,
  isClose: false,
  Imagefile: [],
  linkId: "",
  Croppingimage: "",
  showPopup: false,
  PopShow: false,
  preFilledData: {},
  personalityData: {},
  height_option: 0,
  single_gen: 0,
  single_looking_gen:0,
  age_option: 22,
  location_option: -1,
  occupation_options: "",
  lifestyle_options: -1,
  politics_options: 0,
  age_range_options: { start: 22, end: 99 },
  lifestyle_looking_options: [],
  geography_options: [],
  gender_option: "",
  image_option: [],
  mobile_number: "",
  termPopup1: false,
  save_terms: "",
  signle_documentid: "",
  singles_docid: "",
  docid_exists: "",
  matcher_id: "",
  associated_profiles: [],
  lrpid: "",
  match_reject: "",
  single_existsid: "",
  lhs_keyid: "",
  rhs_keyid: "",
  time_stemp: "",
  lcs_state: "",
  rcs_state: "",
  spid: "",
  lrmpid: "",
};

function Reducer(state = initialState, action = {}) {
  switch (action.type) {
    case "LOADED":
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case phoneNumberToBeVerify:
      return {
        ...state,
        phoneNumberToBeVerify: action.payload,
      };
    case otpConfirmationResult:
      return {
        ...state,
        otpConfirmationResult: action.payload,
      };
    case "LOADING":
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case S_NAME:
      return {
        ...state,
        sName: action.payload,
      };
    case spid:
      return {
        ...state,
        spid: action.payload,
      };
    case lrmpid:
      return {
        ...state,
        lrmpid: action.payload,
      };
    case M_NAME:
      return {
        ...state,
        mName: action.payload,
      };

    case SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };

    case SELECT_INDEX:
      return {
        ...state,
        selectIndex: action.payload,
      };
    case CLOSE_BUTTON:
      return {
        ...state,
        isClose: true,
      };
    case SELECT_IMAGE:
      return {
        ...state,
        imageSlect: action.payload,
      };

    case CLEAR_STATES:
      return {
        ...state,
        isClose: false,
        PopShow: false,
        ShowPopup: false,
        termPopup1: false,
      };

    case UPLOAD_IMAGE:
      return {
        ...state,
        Imagefile: action.payload,
      };

    case USER_ID:
      return {
        ...state,
        linkId: action.payload,
      };
    case CROP_IMAGE:
      return {
        ...state,
        Croppingimage: action.payload,
      };

    case POP_UP:
      return {
        ...state,
        ShowPopup: true,
      };
    case SELECT_POP:
      return {
        ...state,
        PopShow: true,
      };
    case PREFILL_DATA:
      return {
        ...state,
        preFilledData: action.payload,
      };

    case PERSONANLITY_DATA:
      return {
        ...state,
        personalityData: action.payload,
      };
    case REJECTRESON_DATA:
      return {
        ...state,
        rejectReasonData: action.payload,
      };
    case HEIGHT_DATA:
      return {
        ...state,
        height_option: action.payload,
      };
    case SINGLE_GEN:
      return {
        ...state,
        single_gen: action.payload,
      };
      case SINGLE_LOOKING_GEN:
        return {
          ...state,
          single_looking_gen: action.payload,
        };
    case AGE_DATA:
      return {
        ...state,
        age_option: action.payload,
      };
    case LOCATION_DATA:
      return {
        ...state,
        location_option: action.payload,
      };
    case OCCUPATION_DATA:
      return {
        ...state,
        occupation_options: action.payload,
      };
    case LIFESTYLE_DATA:
      return {
        ...state,
        lifestyle_options: action.payload,
      };
    case POLITICS_DATA:
      return {
        ...state,
        politics_options: action.payload,
      };

    case AGE_RANGE_DATA:
      return {
        ...state,
        age_range_options: action.payload,
      };
    case LIFESTYLE_LOOKING_DATA:
      return {
        ...state,
        lifestyle_looking_options: action.payload,
      };
    case GEOGRAPHY_DATA:
      return {
        ...state,
        geography_options: action.payload,
      };
    case USER_GENDER:
      return {
        ...state,
        gender_option: action.payload,
      };
    case IMAGE_DATA:
      return {
        ...state,
        image_option: action.payload,
      };

    case MOBLIE_NUMBER:
      return {
        ...state,
        mobile_number: action.payload,
      };
    case TERMS_POPUP:
      return {
        ...state,
        termPopup1: true,
      };

    case SAVE_TERMS:
      return {
        ...state,
        save_terms: action.payload,
      };

    case SINGLE_DOCID:
      return {
        ...state,
        signle_documentid: action.payload,
      };

    case SINGLES_ID:
      return {
        ...state,
        singles_docid: action.payload,
      };
    case MATCHER_ID:
      return { ...state, matcher_id: action.payload };
    case ASSOCIATED_PROFILES:
      return { ...state, associated_profiles: action.payload };
    case LRPID:
      return { ...state, lrpid: action.payload };
    case MATCH_REJECT:
      return { ...state, match_reject: action.payload };

    case LHS_KEY:
      return { ...state, lhs_keyid: action.payload };
    case RHS_KEY:
      return { ...state, rhs_keyid: action.payload };
    case TIMESTEMP:
      return { ...state, time_stemp: action.payload };
    case LCS_STATE:
      return { ...state, lcs_state: action.payload };
    case RCS_STATE:
      return { ...state, rcs_state: action.payload };

    default:
      return state;
  }
}

export default Reducer;
