import React from 'react'

export default function PageLoder() {
  return (
    <div className="main-home" id="top">
      <div className="container  first-container">
        <div className="row universal-row-box loader-page-row-box">
          <div className="col-12 text-center">
            <div className="loader1">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div></div>
        </div>
      </div>
    </div>
  )
}
