import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import heartpopup2 from "../Common/Assets/Images/heartpopup2.png";
import termCheck from "../Common/Assets/Images/termCheck.png";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../Firebase/Firebase";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getDatabase,
  push,
  ref,
  serverTimestamp as serverTime,
} from "firebase/database";

const lr_pid = new URLSearchParams(window.location.search).get("lrpid");

function HeartPopup2({ csid }) {
  const { t } = useTranslation();
  const { lrpid, rejectReasonData } = useSelector((state) => state);

  const [colrChange, setColrChange] = useState({
    colortext: "",
    clchange: "",
    btcolor: "",
  });

  const [state, setState] = useState(-1);
  const [loader, setloader] = useState(false);
  const [rejectReasonPopup, setRejectReasonPopup] = useState(false);
  const [rrData] = React.useState(rejectReasonData);
  const [selectedData, setSelectedData] = React.useState([]);
  const [rejectReasonText, setRejectReasonText] = React.useState("");

  const lr = lrpid.charAt(0);
  const pid = lrpid.substring(2);

  const Navigate = useNavigate();
  const firestore = getFirestore(app);
  const database = getDatabase(app);

  const ReajectReason = async () => {
    setloader(true);
    if (state === 2) {
      await updateDoc(doc(collection(firestore, "singles"), csid), {
        is_active: false,
        last_updated: serverTimestamp(),
      });
    }

    const proposalSnap = await getDoc(doc(firestore, "proposals", pid));

    const rdocSnap = await getDoc(
      doc(firestore, "single_profiles", proposalSnap.get("rhs_single_profile"))
    );
    const ldocSnap = await getDoc(
      doc(firestore, "single_profiles", proposalSnap.get("lhs_single_profile"))
    );

    var matcherId = "";
    var single_name = "";
    var singleProfilesId = "";

    if (lr === "l") {
      await updateDoc(
        doc(collection(firestore, "proposals"), pid),
        state === 1
          ? {
              lhs_reject_reason: state,
              match_reject_reason: 3,
              lhs_single_reject_reason: {
                single_reject_reason_tags: selectedData,
                text: rejectReasonText,
              },
              updated_at: serverTimestamp(),
            }
          : {
              lhs_reject_reason: state,
              match_reject_reason: 3,
              updated_at: serverTimestamp(),
            }
      );

      single_name = ldocSnap.get("full_name");
      matcherId = proposalSnap.get("lhs_matcher");
      singleProfilesId = proposalSnap.get("lhs_single_profile");
    }

    if (lr === "r") {
      await updateDoc(
        doc(collection(firestore, "proposals"), pid),
        state === 1
          ? {
              rhs_reject_reason: state,
              match_reject_reason: 3,
              rhs_single_reject_reason: {
                single_reject_reason_tags: selectedData,
                text: rejectReasonText,
              },
              updated_at: serverTimestamp(),
            }
          : {
              rhs_reject_reason: state,
              match_reject_reason: 3,
              updated_at: serverTimestamp(),
            }
      );

      single_name = rdocSnap.get("full_name");
      matcherId = proposalSnap.get("rhs_matcher");
      singleProfilesId = proposalSnap.get("rhs_single_profile");
    }

    await push(
      ref(
        database,
        "conversations/" + proposalSnap.get("conversation_id") + "/messages/"
      ),
      {
        sender_id: matcherId,
        content: JSON.stringify({
          english:
            single_name +
            " responded that the offer is not relevant at the moment",
          hebrew: single_name + " הגיב/ה שההצעה לא רלוונטית כרגע",
        }),
        message_type: 5,
        read_status: 0,
        timestamp: serverTime(),
      }
    );

    setloader(false);

    Navigate(`/decline?lrpid=${lr_pid}`);
  };

  const textColrChange = () => {
    setColrChange({
      colortext: "#FFFFFF",
      clchange: "rgba(64, 64, 64, 0.5)",
      btcolor: "#ee2c46",
    });
  };

  return (
    <>
      <Modal show={loader} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div className="popup-3">
        <div
          className="modal heart-popup-3 fade"
          id="exampleModal4"
          // tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog heart-modal-dialog-">
            <div className="modal-content heart-popup-modal-content-2">
              <div className="modal-body">
                <div className="main-img-heartpopup main-img-heartpopup2">
                  <img
                    src={heartpopup2}
                    className="d-block w-100 main-home-profile-img-slide"
                    alt="img-1"
                  />
                </div>
                <h2 className="heart-popup-heading-text">
                  {t("title-web-page-popup2")}
                </h2>
                <div className="heart-popup2-options-box">
                  <div
                    className="heart-select-options1-box"
                    onClick={() => {
                      setState(1);
                      textColrChange();
                    }}
                    style={
                      state === 1
                        ? {
                            backgroundColor: colrChange.clchange,
                            color: colrChange.colortext,
                          }
                        : {}
                    }
                  >
                    {t("title-web-page2-description")}
                  </div>
                  <div
                    className="heart-select-options1-box"
                    onClick={() => {
                      setState(2);
                      textColrChange();
                    }}
                    style={
                      state === 2
                        ? {
                            backgroundColor: colrChange.clchange,
                            color: colrChange.colortext,
                          }
                        : {}
                    }
                  >
                    {t("title-web-page2-btn1")}
                  </div>
                </div>
                <div className="heart-popup-button-box">
                  <button
                    onClick={() => {
                      if (state === 1 || state === 2) {
                        if (state === 1) {
                          setRejectReasonPopup(true);
                        } else {
                          ReajectReason();
                        }
                      }
                    }}
                    type="button"
                    className="btn btn-primary popup-btn-submit "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ backgroundColor: colrChange.btcolor }}
                  >
                    {t("title-web-page2-btn2")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={rejectReasonPopup} className="model-loader">
        <div className="modal-dialog heart-modal-dialog-">
          <div className="modal-content heart-popup-modal-content-2 popup-reject-proposal">
            <div className="modal-body">
              <button
                type="button"
                className="btn modal-close splash-modal-close-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  setRejectReasonPopup(false);
                }}
              >
                {" "}
                <i className="bi bi-x-lg"></i>
              </button>
              <div className="main-img-heartpopup main-img-heartpopup2">
                <img
                  src={heartpopup2}
                  className="d-block w-100 main-home-profile-img-slide"
                  alt="img-1"
                />
              </div>
              <h2 className="heart-popup-heading-text">
                {t("popup-reject-proposal-title")}
              </h2>
              <p>{t("popup-reject-proposal-subtitle")}</p>
              <div className="heart-popup2-options-box popup-reject-proposal-options">
                <div
                  className="row main-home-tags-selected-data-row"
                  style={{
                    display: "flex",
                  }}
                >
                  <div className="col-4 main-home-tags-selected-data-col popup-reject-proposal-chips">
                    {rrData.map((item, index) => {
                      return (
                        <p
                          key={index}
                          className="popup-reject-proposal-chips-p"
                          onClick={() => {
                            var dt = [...selectedData];
                            const index = dt.indexOf(item.id);
                            if (index > -1) {
                              dt.splice(index, 1);
                            } else {
                              dt.push(item.id);
                            }
                            setSelectedData(dt);
                          }}
                          style={{
                            background:
                              selectedData.indexOf(item.id) > -1
                                ? "#EE2C46"
                                : "white",
                            color:
                              selectedData.indexOf(item.id) > -1
                                ? "white"
                                : "#9A9A9A",
                            border:
                              selectedData.indexOf(item.id) > -1
                                ? "1px solid #EE2C46"
                                : "1px solid #D9D9D9",
                          }}
                        >
                          {item.get("hebrew")}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="popup-reject-proposal-input ">
                <input
                  dir="rtl"
                  type="text"
                  name="text"
                  placeholder={t("popup-reject-proposal-input")}
                  id="popup-reject-proposal-input"
                  onChange={(e) => setRejectReasonText(e.target.value)}
                />
              </div>
              <div className="heart-popup-button-box popup-reject-proposal-btn">
                <button
                  onClick={() => {
                    if (selectedData.length > 0 || rejectReasonText != "") {
                      ReajectReason();
                    }
                  }}
                  type="button"
                  className="btn btn-primary popup-btn-submit popup-reject-proposal-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={
                    selectedData.length > 0 || rejectReasonText != ""
                      ? { backgroundColor: "#EE2C46", color: "#FFFFFF" }
                      : { backgroundColor: "white", color: "#EE2C46" }
                  }
                >
                  {t("popup-reject-proposal-btn")}
                </button>
              </div>
              <div className="popup-reject-proposal-bottom-text-div">
                <img
                  className="popup-reject-proposal-bottom-img"
                  src={termCheck}
                  alt=""
                />
                <p>{t("popup-reject-proposal-bottom-text")}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HeartPopup2;
