import React, { useEffect, useState } from "react";
import Logo3 from "../../../Common/Assets/Images/logoWhite3.png";
import "../../../Common/CSS/Style.css";
import { useTranslation } from "react-i18next";
import whatsapp from "../../../Common/Assets/Images/Whatsap.png";
import dummyProfile from "../../../Common/Assets/Images/dummyProfile.png";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import app from "../../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

export default function MatchSuccess() {
  const { t } = useTranslation();

  const [loader, setloader] = useState(false);
  const [msgPopup, setMsgPopup] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState([]);
  const [copy, setCopy] = useState(false);

  const lr_pid = new URLSearchParams(window.location.search).get("lrpid");

  var spid = "";

  useEffect(() => {
    window.scrollTo(0, 0);
    setloader(true);
    if (lr_pid !== null && lr_pid !== "") {
      doesDocExist(lr_pid.charAt(0), lr_pid.substring(2));
    }
  }, []);

  const firestore = getFirestore(app);

  const doesDocExist = async (lr, pid) => {
    const proposalSnap = await getDoc(doc(firestore, "proposals", pid));

    if (proposalSnap.exists()) {
      const spRDocSnap = await getDoc(
        doc(
          firestore,
          "single_profiles",
          proposalSnap.get("rhs_single_profile")
        )
      );
      const spLDocSnap = await getDoc(
        doc(
          firestore,
          "single_profiles",
          proposalSnap.get("lhs_single_profile")
        )
      );

      var sid;

      if (lr === "r") {
        spid = proposalSnap.get("rhs_single_profile");
        sid = proposalSnap.get("lhs_single");
        setName(spLDocSnap.get("full_name"));
        setPhone(spLDocSnap.get("phone_number"));
      } else if (lr === "l") {
        spid = proposalSnap.get("lhs_single_profile");
        sid = proposalSnap.get("rhs_single");
        setName(spRDocSnap.get("full_name"));
        setPhone(spRDocSnap.get("phone_number"));
      }

      const sDocSnap = await getDoc(doc(firestore, "singles", sid));
      setImage(sDocSnap.get("profile_pictures"));
    }

    setloader(false);

    setTimeout(() => {
      setMsgPopup(true);
    }, 2000);
  };

  const copyNumber = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopy(true);
  };

  const onWhatsAppClick = (e) => {
    window.location.href =
      "https://wa.me/" + phone.substring(1, phone.length).replace("-", "");
  };

  const becomeMatcher = async () => {
    const result = await addDoc(collection(firestore, "invite"), {
      code: "1234",
      sender_id: "sp_" + spid,
      create_date: serverTimestamp(),
    });

    const apiKey = "AIzaSyA1ReMg5OXdi3ufNAx8p9BhiQVIoyxCiXI";
    const domainUriPrefix = "https://matchers.page.link";
    const link = `${window.location.origin}/shareit/?id=${result.id}`;
    const androidPackageName = "com.app.matchers.dev";
    const iosBundleId = "com.matchers.app.dev";

    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: domainUriPrefix,
            link: link,
            androidInfo: {
              androidPackageName: androidPackageName,
              androidFallbackLink: link,
            },
            iosInfo: {
              iosBundleId: iosBundleId,
              iosFallbackLink: link,
            },
          },
        }),
      }
    );

    const bodyData = await res.json();

    window.location.replace(bodyData.shortLink);
  };

  return (
    <>
      <Modal show={loader} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div id="top" className="main-home main-home-web-back-set">
        <div className="container first-container">
          <div className="row universal-row-box mat-succ-universal-row-box">
            <div className="col-12 p-0">
              <div className="logo-div main-home-logo-bg">
                <img
                  className="universal-logo match-success-home-logo"
                  src={Logo3}
                  alt="logo"
                />
              </div>
            </div>
            <div className="col-12 mat-succ-data-box">
              <div className="h2-ProposalIrrelevant">
                <h2 className="h2-Ren-said-yes">
                  {t("title-success-page").replace("<sxxxx>", name ? name : "")}
                </h2>
              </div>

              <div>
                <p className="match-success-title">{t("des-success-page")}</p>
              </div>
              <div className="image-group2 matchup-success-img-box-custum">
                <img
                  className="matchup-sccess-main-image"
                  src={image.length > 0 ? image[0].url : dummyProfile}
                  alt="group1"
                />
              </div>
              <div className="h2-ProposalIrrelevant-img-bott-text">
                <h2>{name}</h2>
              </div>
              <div className="matchup-text-img-bottum-text">
                <p
                  className="text-icon-save-contact"
                  onClick={() =>
                    copyNumber(
                      "+" + phone.substring(1, phone.length).replace("-", "")
                    )
                  }
                >
                  {"+" + phone.substring(1, phone.length)}
                </p>
              </div>
              {copy && (
                <p style={{ color: "#dc3545", fontSize: 12 }}>מוּעֲתָק</p>
              )}
              <div className="whatsap-image">
                <img
                  className="image-for-whatsap"
                  src={whatsapp}
                  alt=""
                  onClick={onWhatsAppClick}
                />
              </div>
              <div className="ProposalIrrelevant-button">
                <button
                  type="button"
                  className="match-success-btn"
                  onClick={becomeMatcher}
                >
                  {" "}
                  {t("btn-success-page")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {msgPopup && (
        <>
          <div className="popup-2">
            <div className="popup-good popup-existing-profile "></div>
            <div
              className="modal d-block splash-auto-open-popup"
              onClick={() => {}}
            >
              <div className="modal-dialog success-modal">
                <div className="modal-content success-modal-content">
                  <div className="modal-body success-modal-body">
                    <h2 className="success-title-top2">
                      {t("match-success-title")}
                    </h2>
                    <p className="success-text-popup">
                      <span className="match-success-text-1">
                        {t("match-success-text-1")}
                      </span>
                      <span>
                        <span className="match-success-text-2">
                          {t("match-success-text-2")}
                        </span>
                        <span className="match-success-text-3-bold">
                          {t("match-success-text-3-bold")}
                        </span>
                      </span>
                      <span className="match-success-text-4">
                        {t("match-success-text-4")}
                      </span>
                      <span className="match-success-text-5">
                        {t("match-success-text-5")}
                      </span>
                    </p>

                    <button
                      className="button-exit-success "
                      onClick={() => {
                        setMsgPopup(false);
                      }}
                    >
                      {t("match-success-btn-text")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
