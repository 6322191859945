import React, { useState } from "react";
import Popup1 from "../Component/ExitPopup1";
import Popup2 from "../Component/HomePopup";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import topBg from "../Common/Assets/Images/home1-top-bg.png";
import BottumBg from "../Common/Assets/Images/home1-bottum-bg.png";
import { SAVE_TERMS, TERMS_POPUP } from "../Redux/constants";
import { useNavigate } from "react-router-dom";

import { getFirestore, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import app, { appAuthentication } from "../Firebase/Firebase";

const linkId = new URLSearchParams(window.location.search).get("id");
const sName = new URLSearchParams(window.location.search).get("sn");
const mName = new URLSearchParams(window.location.search).get("mn");

function Home(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { isClose, singles_docid } = useSelector((state) => state);

  const [loader, setloader] = useState(false);
  const [showDialog, setShowDialog] = useState(isClose);

  const deleteProfile = async () => {
    setloader(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", linkId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const res = await fetch(
      "https://matcher-prod-api.web.app/single_profile_delete",
      requestOptions
    );

    console.log(await res.json());

    setloader(false);

    setTimeout(() => {
      setShowDialog(true);
    }, 500);
  };


  const btnClick = async () => {
    dispatch({ type: TERMS_POPUP, payload: 3 });

    var unsubscribe = onAuthStateChanged(appAuthentication, async (user) => {
      if (user) {
        const firestore = getFirestore(app);
        const docSnap = await getDoc(doc(firestore, "single_profiles", linkId));

        if (
          docSnap.exists() &&
          docSnap.get("phone_number").replace("-", "") == user.phoneNumber
        ) {
          if (singles_docid != null && singles_docid != "") {
            Navigate(`/check-details?id=${linkId}`);
          } else {
            Navigate(`/user-details-select?id=${linkId}`);
          }
        } else {
          appAuthentication.signOut().then(
            function () {
              Navigate(`/signin`);
            },
            function (error) {
              console.log("error -- ", error.message);
              return false;
            }
          );
        }
      } else {
        unsubscribe();
        Navigate(`/signin`);
      }
    });
  };

  return (
    <>
      <div className="main-home">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container">
          <div className="row universal-row-box home-universal-row-box">
            <div className="col-12">
              <div className="container-fluid">
                <div className="row home-header-box all-data-center-box">
                  <img
                    className="img-fluid universal-logo"
                    alt="logo"
                    src="./images/logo.png"
                  />
                </div>
                <div className="row home-body-box all-data-center-box">
                  <h2>{t("title").replace("<xxxxx>", sName.split(" ")[0])}</h2>
                  <p className="homepage-description">
                    <span>{t("description").replace("<mxxxx>", mName)}</span>
                    <span>
                      {t("description-2")}
                      <span className="description-2-red">
                           <strong>{t("description-2-red")}</strong>
                      </span>
                      {","}
                    </span>
                    <strong>{t("description-3")} </strong>
                    <strong>{t("description-4")} </strong>
                  </p>
                  <div className="home-body-main-img-box all-data-center-box">
                    <img
                      className="img-fluid home-vector-screen-1"
                      src="./images/homeMain.png"
                      alt="homeMain"
                    />
                    <h4 className="mt-4 home-sub-heading">{t("sub-heading")} </h4>
                  </div>
                  <div className="home-body-top-box all-data-center-box">
                    <div className="card-data-box all-data-center-box">
                      <h4 className="text-numbering">1</h4>
                      <p className="number-card-peh-text">
                        <span>{t("1-para-1")}</span>
                        <span>{t("1-para-2")}</span>
                      </p>
                    </div>
                    <div className="card-data-box all-data-center-box">
                      <h4 className="text-numbering">2</h4>
                      <p className="number-card-peh-text">
                        
                        <span>{t("2-para-1")}</span>
                        <span>{t("2-para-2")}</span>
                        <span>{t("2-para-3")}</span>
                      </p>
                    </div>
                    <div className="card-data-box all-data-center-box">
                      <h4 className="text-numbering">3</h4>
                      <p className="number-card-peh-text">
                        <span>{t("3-para-1")}</span>
                        <span>{t("3-para-2")}</span>
                        <span>{t("3-para-3")}</span>
                      </p>
                    </div>
                  </div>

                  <div className="home-body-bottum-box all-data-center-box">
                    <img
                      className="img-fluid"
                      src="./images/VectorQuestion.png"
                      alt="vectorquestion"
                    />
                    <p className="question-box-peh-home">
                      <p className="question-box-peh-home-top">
                        {" "}
                        <b>{t("question-title-top")}</b>
                      </p>

                      <b>{t("question-title")}</b>
                    </p>
                    <h2 className="home-title-2">
                      <span>{t("home-title-2").replace("<mxxxx>", mName)}</span>
                      <span>{t("home-title-2-1")}</span>
                      </h2>
                  </div>
                </div>

                <div className="row home-footer-box all-data-center-box">
                  <button
                    type="button"
                    className="btn btn-primary fill-button-universal home-page-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    onClick={btnClick}
                  >
                    {t("home-page-btn")}
                  </button>
                  <button onClick={() => Navigate(`/terms`)} className="bi">
                  <span className="bi-reg">{t("des-link")}</span>
    <span className="bi">{t("link")}</span>
</button>
                  <button
                    type="button"
                    className="btn btn-primary submit-button-check home-page-btn-2"
                    onClick={deleteProfile}
                  >
                    <div className="text-icon">
                      {isClose && <i className="bi bi-check-circle"></i>}
                      {t("home-page-btn-2")}
                    </div>
                  </button>
                </div>
                <div className="row home-footer-link-box all-data-center-box">
                  <a href="https://www.matchers.ai/">{t("ques-title-last")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom select-detail-universal-bac-bottum">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
      <Popup1 showDialog={showDialog} />
    </>
  );
}

export default Home;
