export const setLogcat = async (logName, severity, message) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("logName", logName);
  urlencoded.append("severity", severity.toUpperCase()); //ERROR, INFO, WARN ...
  urlencoded.append("message", message);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const res = await fetch(
    "https://matcher-prod-api.web.app/logcat",
    requestOptions
  );

  const result = await res.json();

  return result;
};
