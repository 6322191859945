import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_TERMS, SELECT_POP, SINGLE_LOOKING_GEN } from "../../Redux/constants";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

export default function SelectOption() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const Navigate = useNavigate();

  // this side get value from dispacth
  const {
    personalityData,
    mobile_number,
    height_option,
    age_option,
    location_option,
    occupation_options,
    lifestyle_options,
    politics_options,
    age_range_options,
    lifestyle_looking_options,
    geography_options,
    gender_option,
    image_option,
    save_terms,
    singles_docid,
    matcher_id,
    associated_profiles,
  } = useSelector((state) => state);

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = React.useState([]);
  const [UserList] = React.useState(personalityData);
  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const firestore = getFirestore(app);

  // this side add looking_personality_traits in firebase and serverTimestamp.

  var submit = true;

  const submitData = async () => {
    // image data send with array and with url, height, width, order,

    var imgData = [];

    for (let i = 0; i < image_option.length; i++) {
      var option = image_option[i];
      if (option.url != "") {
        imgData.push({
          url: option.url,
          order: i,
          height: option.height,
          width: option.width,
        });
      }
    }

    if (imgData.length <= 0) {
      alert("Profile image not selected.");
      return true;
    }

    setShow(true);

    try {
      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          requested_personality_traits: selectedData,
          last_updated: serverTimestamp(),
        });
      } else {
        var associated_matchers = [];
        associated_profiles.forEach((profile) => {
          associated_matchers.push(profile.matcher_id);
        });

        const documentSnap = await getDoc(
          doc(firestore, "matchers", matcher_id)
        );

        var data = documentSnap.exists()
          ? documentSnap.get("represented_singles") != null
            ? documentSnap.get("represented_singles")
            : []
          : [];

        // send all data update in singles collection

        if (singles_docid != null && singles_docid != "") {
          await updateDoc(
            doc(collection(firestore, "singles"), singles_docid),
            {
              profile_pictures: imgData,
              height: height_option,
              phone_number: mobile_number,
              looking_for_gender: gender_option,
              requested_personality_traits: selectedData,
              is_active: true,
              associated_profiles: [
                ...associated_profiles,
                { id: linkId, approved: true, matcher_id: matcher_id },
              ],
              associated_matchers: [...associated_matchers, matcher_id],
              last_updated: serverTimestamp(),
              birth_year: new Date().getFullYear() - age_option,
              location: location_option,
              occupation: occupation_options,
              religious_view: lifestyle_options,
              political_factor: politics_options,
              age_range: age_range_options,
              lifestyle_looking: lifestyle_looking_options,
              geography: geography_options,
            }
          );

          // and update accepted_eula_date key into singles collection

          // var approved = 0;
          // if (save_terms !== null && save_terms !== "") {
          //   approved = 1;
          //   await updateDoc(doc(collection(firestore, "singles"), singles_docid), {
          //     accepted_eula_date: save_terms,
          //   });
          // }

          // and update single_id key into singles_profiles collection

          await updateDoc(
            doc(collection(firestore, "single_profiles"), linkId),
            {
              single_id: singles_docid,
            }
          );

          const representedSingles = data.map((item) => {
            if (item.id === linkId) {
              return { ...item, single_id: singles_docid, approved: 1 };
            }
            return item;
          });

          // and update represented_singles key into matchers collection
          await updateDoc(doc(collection(firestore, "matchers"), matcher_id), {
            represented_singles: representedSingles,
          });
        } else {
          //  // send all data add in singles collection
          const docRef = await addDoc(collection(firestore, "singles"), {
            profile_pictures: imgData,
            height: height_option,
            phone_number: mobile_number,
            looking_for_gender: gender_option,
            requested_personality_traits: selectedData,
            associated_profiles: [
              ...associated_profiles,
              { id: linkId, approved: true, matcher_id: matcher_id },
            ],
            is_active: true,
            associated_matchers: [...associated_matchers, matcher_id],
            last_updated: serverTimestamp(),
            created_at: serverTimestamp(),
            birth_year: new Date().getFullYear() - age_option,
            location: location_option,
            occupation: occupation_options,
            religious_view: lifestyle_options,
            political_factor: politics_options,
            age_range: age_range_options,
            lifestyle_looking: lifestyle_looking_options,
            geography: geography_options,
          });

          // and update accepted_eula_date key into singles collection

          var approved = 0;
          if (save_terms !== null && save_terms !== "") {
            approved = 1;
            await updateDoc(doc(collection(firestore, "singles"), docRef.id), {
              id: docRef.id,
              accepted_eula_date: save_terms,
            });
          }

          // and update single_id key into singles_profiles collection
          await updateDoc(
            doc(collection(firestore, "single_profiles"), linkId),
            {
              single_id: docRef.id,
            }
          );

          const representedSingles = data.map((item) => {
            if (item.id === linkId) {
              return { ...item, single_id: docRef.id, approved: approved };
            }

            return item;
          });

          // and update represented_singles key into matchers collection
          await updateDoc(doc(collection(firestore, "matchers"), matcher_id), {
            represented_singles: representedSingles,
          });
        }
      }

      setShow(false);

      if (linkId != null && linkId != "") {
        // window.location.href = window.location.href + `/?id=${linkId}`;
        Navigate(`/splash?id=${linkId}`);
      } else {
        Navigate(`/splash?sid=${sid}`);
      }

      dispatch({
        type: SAVE_TERMS,
        payload: null,
      });


      dispatch({
        type: SINGLE_LOOKING_GEN,
        payload: gender_option,
      });

    } catch (e) {
      setShow(false);
      alert("Error: " + e.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (image_option == "" && height_option == "" && gender_option == "") {
      if (linkId != null && linkId != "") {
        Navigate(`/?id=${linkId}`);
      } else {
        Navigate(`/`);
        window.location.reload();
      }
    }

    if (sid != null && sid != "") {
      getData();
    }
  }, []);

  const getData = async () => {
    setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));
    const personalityTraits = docSnap.get("requested_personality_traits");
    setSelectedData(personalityTraits);
    setShow(false);
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="main-home user-gender ">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row main-home-gender2-page universal-row-box">
            <div className="col-12 matchup-col">
              <div className="logo-div">
                <img className="universal-logo" src={Logo} alt="" />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div
                    className="col-1 indicator-blank"
                    style={
                      selectedData.length > 0
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "rgba(238, 44, 70, 0.3)" }
                    }
                  ></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div">
                <p className="text-1 gender2-text">
                  {t("select-option-title")}
                </p>
                <p className="gender2-text2">{t("select-option-paragraph")}</p>
              </div>
              <ul className="list-btn-data-box">
                {UserList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      id="list_item"
                      onClick={() => {
                        var dt = [...selectedData];
                        const index = dt.indexOf(item.id);
                        if (index > -1) {
                          dt.splice(index, 1);
                        } else {
                          if (dt.length < 5) {
                            dt.push(item.id);
                          }
                        }
                        setSelectedData(dt);
                      }}
                      style={{
                        background:
                          selectedData.indexOf(item.id) > -1
                            ? "#EE2C46"
                            : "white",
                        color:
                          selectedData.indexOf(item.id) > -1
                            ? "white"
                            : "#9A9A9A",
                        border:
                          selectedData.indexOf(item.id) > -1
                            ? "1px solid #EE2C46"
                            : "1px solid #D9D9D9",
                      }}
                    >
                      <a>{item.get("hebrew")}</a>
                    </li>
                  );
                })}
              </ul>

              <div className="image-group1">
                <div className="button-div">
                  <button
                    type="button"
                    style={
                      selectedData.length > 0
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "grey" }
                    }
                    className="btn btn-primary button-genders click-gender"
                    data-bs-toggle={selectedData.length > 0 && "modal"}
                    data-bs-target={selectedData.length > 0 && "#exampleModal3"}
                    onClick={() => {
                      if (
                        (linkId || sid) &&
                        selectedData.length > 0 &&
                        submit
                      ) {
                        submit = false;
                        submitData();
                      }
                      dispatch({ type: SELECT_POP, payload: 2 });
                    }}
                  >
                    {t("select-option-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
}
