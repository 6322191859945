import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logoexit from "../../Common/Assets/Images/logoexit.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/splash-top-bg.png";
import BottumBg from "../../Common/Assets/Images/splash-bottum-bg.png";
import popupImage from "../../Common/Assets/Images/popup-image.png";
import popupBecomeMatcherImg from "../../Common/Assets/Images/popupBecomeMatcherImg.png";
import appstore from "../../Common/Assets/Images/appstore.png";
import googleplay from "../../Common/Assets/Images/googleplay.png";

import { useDispatch, useSelector } from "react-redux";
import { CLEAR_STATES } from "../../Redux/constants";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";

export default function Splash(props) {
  const { ShowPopup, PopShow } = useSelector((state) => state);

  const [pop_show, setPopShow] = useState(false);
  const [show_popup, setShowPopup] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const StartOver = () => {
    window.location.replace("/");
  };

  const firestore = getFirestore(app);

  const becomeMatcher = async () => {
    const result = await addDoc(collection(firestore, "invite"), {
      code: "1234",
      sender_id: linkId == null || linkId == "" ? "s_" + sid : "sp_" + linkId,
      create_date: serverTimestamp(),
    });

    const apiKey = "AIzaSyA1ReMg5OXdi3ufNAx8p9BhiQVIoyxCiXI";
    const domainUriPrefix = "https://matchers.page.link";
    const link = `${window.location.origin}/shareit/?id=${result.id}`;
    const androidPackageName = "com.app.matchers.dev";
    const iosBundleId = "com.matchers.app.dev";

    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: domainUriPrefix,
            link: link,
            androidInfo: {
              androidPackageName: androidPackageName,
              androidFallbackLink: link,
            },
            iosInfo: {
              iosBundleId: iosBundleId,
              iosFallbackLink: link,
            },
          },
        }),
      }
    );

    const bodyData = await res.json();

    window.location.replace(bodyData.shortLink);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    if (linkId != null && linkId != "") {
      setPopShow(PopShow);
      setShowPopup(ShowPopup);
    }

    // window.onbeforeunload = function () {
    //   window.location.replace(`/?id=${linkId}`);
    //   return true;
    // };

    // return () => {
    //   window.onbeforeunload = null;
    // };
  }, []);

  return (
    <>
      <div className="main-home splash-set-bg-main-home" id="popup">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row  universal-row-box splash-scren-row-box">
            <div className="col-12 matchup-col matchup2 splash-machup-col p-0">
              <div className="splash-logo-div">
                <img className="logo-1" src={Logoexit} alt="" />
              </div>

              <div className="text-logo-div-splash">
                <p className="text-1 paragraph-text pehragraph-splash pehra-exit">
                  {" "}
                  {t("splash-page-title")}
                </p>
              </div>

              <div className="splash-button-div">
                <button
                  className="button-accept button-blank-box button_exit "
                  onClick={becomeMatcher}
                >
                  {t("splash-page-button1")}
                </button>
                <button
                  onClick={() => StartOver()}
                  className="button-accept button-blank-box button_exit"
                  style={{ backgroundColor: "transparent", color: "white" }}
                >
                  {t("splash-page-button2")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>

      {/* first modal */}

      {show_popup && (
        <>
          <div className="popup-good popup-existing-profile "></div>
          <div
            className="modal d-block splash-auto-open-popup"
            onClick={() => {
              setShowPopup(false);
              dispatch({ type: CLEAR_STATES });
            }}
          >
            <div className="modal-dialog splash-modal-dialog">
              <div className="modal-content splash-modal-content-2 ">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn modal-close splash-modal-close-btn"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setShowPopup(false);
                      dispatch({ type: CLEAR_STATES });
                    }}
                  >
                    {" "}
                    <i className="bi bi-x-lg"></i>
                  </button>
                  <div className="main-img-heartpopup main-img-existing-popup ">
                    <img
                      src={popupImage}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                    />
                  </div>
                  <h2 className="popup-title-top main-existing-popup-title">
                    {t("title-existing profile")}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* second popup */}

      {pop_show && (
        <>
          <div className="popup-2">
            <div className="popup-good popup-existing-profile "></div>
            <div
              className="modal d-block splash-auto-open-popup"
              onClick={() => {
                dispatch({ type: CLEAR_STATES });
                setPopShow(false);
                setShowDialog(true);
              }}
            >
              <div className="modal-dialog splash-modal">
                <div className="modal-content splash-modal-content splash-modal-content-new" >
                  <div className="modal-body modal-body-new">
                    <button
                      type="button"
                      className="btn modal-close splash-modal-close-btn"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        dispatch({ type: CLEAR_STATES });
                        setPopShow(false);
                        setShowDialog(true);
                      }}
                    >
                      <i className="bi bi-x-lg"></i>
                    </button>
                    <div className="popup-submit-div">
                      <h2 className="popup-title-top2">
                        {t("popup-submit-title")}
                      </h2>
                      <p className="para-popup para-popup-new">{t("popup-submit-des")}</p>
                    </div>
                   
                    <p className="para-popup-subtitle">
                      <span>
                        {t("popup-submit-des-subtitle1")}
                      </span>
                      <span>
                        {t("popup-submit-des-subtitle2")}
                      </span>
                      
                    </p>

                    <button
                      className="button-exit-popup button-exit-popup-new"
                      onClick={() => {
                        dispatch({ type: CLEAR_STATES });
                        setPopShow(false);
                        setShowDialog(true);
                      }}
                    >
                      {t("popup-submit-button-new")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* become matcher popup */}
      {showDialog && (
        <>
          <div className="popup-good popup-existing-profile "></div>
          <div className="modal d-block splash-auto-open-popup">
            <div className="modal-dialog splash-modal-dialog">
              <div className="modal-content splash-modal-content-2 modal-content-change">
                <div className="modal-body">
                  <div className="main-img-heartpopup main-img-existing-popup popup-change">
                    <img
                      src={popupBecomeMatcherImg}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                    />
                  </div>
                  <h2 className="popup-title-top main-existing-popup-title">
                    
                    <span>{t("title_become_matcher_1")}</span><br />
                    <span>{t("title_become_matcher_2")}</span>
                  </h2>

                  <div class="google-figure">
                    <img
                      src={googleplay}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                      onClick={() => {
                        window.location.href =
                          "https://play.google.com/store/apps/details?id=com.matchersapp.app";
                      }}
                    />
                  </div>

                  <div class="google-figure">
                    <img
                      src={appstore}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                      onClick={() => {
                        window.location.href =
                          "https://apps.apple.com/app/id1640479105";
                      }}
                    />
                  </div>
                  <div class="change-btn">
                    <button
                      onClick={() => setShowDialog(false)}
                      className="button-accept button-blank-box button_exit"
                      style={{ backgroundColor: "transparent", color: "white" }}
                    >
                      {t("btn_become_matcher")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
