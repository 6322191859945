import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CLOSE_BUTTON } from "../Redux/constants";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function Popup1(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linkId = new URLSearchParams(window.location.search).get("id");

  function handleAnywhereClickWhenPopupAppears() {
    window.addEventListener("click", function (e) {
      if (e != null && e.target != null) {
        if (props.showDialog) {
          navigate(`/splash?id=${linkId}`);
          window.location.reload();
        }
      }
    });
  }

  handleAnywhereClickWhenPopupAppears();
  return (
    <Modal show={props.showDialog}>
    <div className="popup-1" id="popup-1">
      <div className="modal-content exit-content">
        <div className="modal-header">
          <button
            type="button"
            className="btn modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              navigate(`/splash?id=${linkId}`);
            }}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
        <div className="modal-body">
          <img className="img-fluid" alt="" src="./images/Frame-popup1.png" />
          <h2>
            <b>{t("popup2-title")}</b>
          </h2>
        </div>
      </div>
    </div>
  </Modal>
  );
}
export default Popup1;
