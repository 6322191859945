// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from 'firebase/auth';

// match side configuration this side


//For Production mode

const firebaseConfig = {
  apiKey: "AIzaSyBJUwySQYBXE7ytorMCF2Qdykck8Bg9QtE",
  authDomain: "matchup-9ebf8.firebaseapp.com",
  databaseURL: "https://matchup-9ebf8-default-rtdb.firebaseio.com",
  projectId: "matchup-9ebf8",
  storageBucket: "matchup-9ebf8.appspot.com",
  messagingSenderId: "331644692104",
  appId: "1:331644692104:web:6b61761c3e81ab1020e13d",
  measurementId: "G-TNFPCRXRFM",
};
export const baseApiUri = "https://us-central1-matchup-9ebf8.cloudfunctions.net";


// //For Development mode

// const firebaseConfig = {
//   apiKey: "AIzaSyA1ReMg5OXdi3ufNAx8p9BhiQVIoyxCiXI",
//   authDomain: "matchers-dev.firebaseapp.com",
//   databaseURL: "https://matchers-dev-default-rtdb.firebaseio.com",
//   projectId: "matchers-dev",
//   storageBucket: "matchers-dev.appspot.com",
//   messagingSenderId: "830354158720",
//   appId: "1:830354158720:web:07215bc98abd325caea265",
//   measurementId: "G-QSX1MH5XFJ"
// };
// export const baseApiUri = "https://us-central1-matchers-dev.cloudfunctions.net";



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
export const appAuthentication = getAuth(app);
export const apiRouteVerifyPhoneNumber = "/verifyPhoneNumber";
export const apiRouteSignInWithMatchersAccount = "/signInWithMatchersAccount";