import React, { useEffect, useRef, useState } from "react";
import mainsignin from "../../../Common/Assets/Images/signin.png";
import mainsigninProposal from "../../../Common/Assets/Images/signin_proposal.png";
// import BottumBg from "../../../Common/Assets/Images/home1-bottum-bg.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import {
  signInWithPhoneNumber,
  RecaptchaVerifier,
  onAuthStateChanged,
} from "firebase/auth";
import app, { appAuthentication, baseApiUri, apiRouteVerifyPhoneNumber, } from "../../../Firebase/Firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  phoneNumberToBeVerify,
  otpConfirmationResult,
  spid,
  lrmpid,
} from "../../../Redux/constants";
import { Modal } from "react-bootstrap";
import { setLogcat } from "../../../Common/set_logcat";

const Signin = (props) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [countryCode, setCountryCode] = useState("+972");
  const [phoneNumber, setPhoneNumber] = useState("+972");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [buttonDisableStatus, setButtonDisableStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = "single";

  const recaptchaWrapperRef = useRef();

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("RecaptchaVerifier Response : ", response);

            setLogcat(
              "SignInLogs",
              "INFO",
              "(" +
                phoneNumber +
                ") RecaptchaVerifier Response : " +
                JSON.stringify(response)
            );
          },
        },
        appAuthentication
      );
    } catch (er) {
      console.log("RecaptchaVerifier Error : ", er);

      setLogcat(
        "SignInLogs",
        "ERROR",
        "(" + phoneNumber + ") RecaptchaVerifier Error : " + er.message
      );
    }
  };

  const requestOtp = async (e) => {
    if (phoneNumber === "" || phoneNumber.length < 10) {
      setError("מספר טלפון שגוי");
      setErrorMessage(true);
      return false;
    }

    setError("");
    setErrorMessage(false);
    setShow(true);
    generateRecaptcha();
    console.log("PhoneNumber : ", phoneNumber);
    const backendUrl = `${baseApiUri}${apiRouteVerifyPhoneNumber}?phoneNumber=${phoneNumber}&userType=${userType}`;
    fetch(backendUrl, {mode: 'cors'})
    .then((response) => {
      // First, ensure that the response is okay and parse it as JSON
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
      .then((verifyPhoneNumberResponseData) => {
        const confirmationResult = verifyPhoneNumberResponseData.verification_id;
        console.log("ConfirmationResult : ", confirmationResult);

        setLogcat(
          "SignInLogs",
          "INFO",
          "(" +
            phoneNumber +
            ") ConfirmationResult : " +
            JSON.stringify(confirmationResult)
        );

        window.confirmationResult = confirmationResult;

        dispatch({
          type: otpConfirmationResult,
          payload: confirmationResult,
        });

        dispatch({
          type: phoneNumberToBeVerify,
          payload: phoneNumber
            .replace(countryCode, countryCode + "-")
            .replace("-0", "-"),
        });

        dispatch({
          type: spid,
          payload: props.id,
        });

        dispatch({
          type: lrmpid,
          payload: props.lrpid,
        });

        setButtonDisableStatus(false);

        if (props.id != null && props.id != "") {
          navigate("/verify-otp?id=" + props.id);
        } else if (props.lrpid != null && props.lrpid != "") {
          navigate("/verify-otp?lrpid=" + props.lrpid);
        } else {
          navigate("/verify-otp");
        }
      })
      .catch((error) => {
        console.log("Error : ", error);

        setLogcat(
          "SignInLogs",
          "ERROR",
          "(" + phoneNumber + ") Error : " + error.message
        );

        setShow(false);
        setError(error.message);
        if (error.message.includes("invalid-phone-number")) {
          setError("מספר טלפון שגוי");
        }
        if (error.message.includes("internal-error")) {
          setError("שגיאת שרת פנימית");
        }
        if (error.message.includes("network-request-failed")) {
          setError("בקשת הרשת נכשלה");
        }
        if (error.message.includes("too-many-requests")) {
          setError("הוגשו יותר מדי בקשות, אנא נסה שוב מאוחר יותר");
        }
        setErrorMessage(true);
        setButtonDisableStatus(false);
        return false;
      });

    setButtonDisableStatus(true);
  };

  return (
    <>
      {/* loading modle */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid home-universal-container-box">
        <div className="main-home signin-main-home-box">
          <div className="container ">
            <div className="row universal-row-box home-universal-row-box">
              <div className="col-12 indicator home-universal-col-box">
                <div className="container-fluid">
                  <div className="row home-header-box all-data-center-box">
                    <img
                      className="img-fluid universal-logo universal-logo-top-distance"
                      alt="logo"
                      src="./images/logo.png"
                    />
                  </div>
                  <div
                    className={`row universal-center-box-data signin-screen-custum-design-row ${
                      !errorMessage
                        ? "signin-screen-custum-design-row"
                        : "signin-screen-custum-design-row-err"
                    }`}
                  >
                    {props.id !== null && props.id !== "" ? (
                      <h2>
                        רק מאמתים זריז את
                        <br />
                        הזהות שלך
                      </h2>
                    ) : props.lrpid !== null && props.lrpid !== "" ? (
                      <h2>
                        התחברות מהירה
                        <br />
                        כדי לצפות בהצעה
                      </h2>
                    ) : (
                      <h2>כניסה לאזור האישי</h2>
                    )}

                    <div className="signin-screen-custum-design-img-box universal-center-box-data">
                      <img
                        className="img-fluid home-vector-screen-1"
                        src={
                          props.id !== null && props.id !== ""
                            ? mainsignin
                            : props.lrpid !== null && props.lrpid !== ""
                            ? mainsigninProposal
                            : mainsignin
                        }
                        alt="homeMain"
                      />
                    </div>
                    <div className="signin-input-box-top-text universal-center-box-data">
                      <h4 className="">מה הטלפון שלך?</h4>
                    </div>
                    <div className="signin-screen-custum-design-input-box universal-center-box-data">
                      <label className="custom-field one">
                        <PhoneInput
                          className={`input-field-mobile-number-custum-box`}
                          country={"il"}
                          enableSearch={true}
                          value={phoneNumber}
                          onChange={(phone, country, e, formattedValue) => {
                            setCountryCode(`+${country.dialCode}`);
                            setPhoneNumber(`+${phone}`);
                            setErrorMessage(false);
                          }}
                          placeholder=""
                        />
                        {errorMessage && (
                          <div className="error-message-box-signin">
                            {error}
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="signin-screen-custum-design-input-button-box universal-center-box-data">
                      <button
                        type="button"
                        className="btn btn-primary signin-button-box"
                        onClick={requestOtp}
                        disabled={buttonDisableStatus}
                      >
                        שילחו קוד לנייד
                      </button>
                    </div>
                    {/* <div className="signin-screen-convert-english universal-center-box-data">
                  <a href="#">Switch to English</a>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={recaptchaWrapperRef}>
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </>
  );
};

export default Signin;
