import React from "react";
import personalAreaImg from "../../../Common/Assets/Images/Group-last.png";

export default function ProfileDeleteSplash() {
 
  return (
    <>
      <div className="container-fluid home-universal-container-box">
        <div className="main-home personal-splash-main-home-box">
          <div className="container ">
            <div className="row universal-row-box home-universal-row-box">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row home-header-box personal-area-home-header-box all-data-center-box">
                    <img
                      className="img-fluid universal-logo universal-logo-top-distance"
                      alt="logo"
                      src="./images/logo.png"
                    />
                  </div>
                </div>
              </div>
              <div className="row personal-splash-screen-custum-design-row universal-center-box-data">
                <h2>הפרופיל נמחק</h2>
                <div className="personal-splash-screen-custum-design-img-box universal-center-box-data">
                  <img
                    className="img-fluid home-vector-screen-1"
                    src={personalAreaImg}
                    alt="homeMain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
