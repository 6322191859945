import React, { useEffect, useState } from "react";
import Picker from "react-scrollable-picker";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AGE_RANGE_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

const SelectAgeRange = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [start_age, setStartAge] = useState("22");
  const [end_age, setEndAge] = useState("22");

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [show, setShow] = useState(false);

  const [message, setMessage] = useState("");

  const firestore = getFirestore(app);

  const [data1, setData1] = useState({
    valueGroups: {
      title: "Age1",
    },
    // default value set
    optionGroups: {
      title: [{ value: "22", label: "22" }],
    },
  });

  const [data2, setData2] = useState({
    valueGroups: {
      title: "Age2",
    },
    // default value set
    optionGroups: {
      title: [{ value: "22", label: "22" }],
    },
  });

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    var _arr = [];
    for (let i = 99; i >= 18; i--) {
      _arr.push({ label: i.toString(), value: i.toString() });
    }

    var dt1 = { ...data1 };
    dt1.optionGroups = { title: _arr };
    setData1(dt1);

    var dt2 = { ...data2 };
    dt2.optionGroups = { title: _arr };
    setData2(dt2);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("overflow", "hidden", "important");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("overflow", "visible", "important");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const handleChange1 = async (name, value) => {
    setStartAge(value);
    setMessage("");

    var dt1 = { ...data1 };
    dt1.valueGroups = {
      ...dt1.valueGroups,
      [name]: value,
    };
    setData1(dt1);
  };

  const handleChange2 = async (name, value) => {
    setEndAge(value);
    setMessage("");

    var dt2 = { ...data2 };
    dt2.valueGroups = {
      ...dt2.valueGroups,
      [name]: value,
    };
    setData2(dt2);
  };

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const age_range = docSnap.get("age_range");

      if (age_range != null) {
        setData1();
        setData2();

        var dt1 = { ...data1 };
        dt1.optionGroups = {
          title: [
            {
              label: age_range["start"].toString(),
              value: age_range["start"].toString(),
            },
          ],
        };

        setData1(dt1);

        var dt2 = { ...data2 };
        dt2.optionGroups = {
          title: [
            {
              label: age_range["end"].toString(),
              value: age_range["end"].toString(),
            },
          ],
        };

        setData2(dt2);
      }
    }

    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    const age_range_options = {
      start: parseInt(start_age),
      end: parseInt(end_age),
    };

    console.log("age_range_options", age_range_options);

    setMessage("");

    if (age_range_options.start > age_range_options.end) {
      setMessage("גיל הסיום לא יכול להיות קטן יותר מגיל ההתחלה.");
    } else {
      dispatch({ type: AGE_RANGE_DATA, payload: age_range_options });

      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          age_range: age_range_options,
          last_updated: serverTimestamp(),
        });
      }

      if (linkId != null && linkId != "") {
        Navigate(`/select-lifestyle-looking?id=${linkId}`);
      } else {
        Navigate(`/select-lifestyle-looking?sid=${sid}`);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div className="main-home age-range" style={{ height: window.innerHeight }}>
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine"
                  src={Logo}
                  alt=""
                />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>

              <div className="logo-div ">
                <p className="text-1 gender-1-text">
                  <b>{"מה טווח הגילאים?"}</b>
                </p>
              </div>

              <div class="change-wrap">
                <div class="first">
                  <p class="rnage-content">ועד-</p>

                  <div className="rotation change-ar">
                    <Picker
                      optionGroups={data2.optionGroups}
                      valueGroups={data2.valueGroups}
                      onChange={handleChange2}
                      height={330}
                      smooth={true}
                    />
                  </div>
                </div>

                <div class="first">
                  <p class="rnage-content">החל מ-</p>

                  <div className="rotation change-ar">
                    <Picker
                      optionGroups={data1.optionGroups}
                      valueGroups={data1.valueGroups}
                      onChange={handleChange1}
                      height={330}
                      smooth={true}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: -45, marginLeft: 20, marginRight: 20 }}>
                <p style={{ color: "#ee2c46", fontSize: 12 }}>{message}</p>
              </div>

              <div className="image-group1 age age-range">
                <div className="button-div">
                  <button
                    className="button-accept profile-button"
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectAgeRange;
