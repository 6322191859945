import React, { useEffect } from "react";

export default function Authentication() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  return (
    <>
      <div className="main-home ">
        <div className="universal-background-top"></div>
        <div className="container  first-container">
          <div className="row universal-row-box select-detail-universal-row-box">
            <div className="col-12 reload-page-col matchup2">
              <div className=" reload-page-again">
                <h1 style={{ color: "#ee2c46" }}>אופס!</h1>
                <h3 style={{ color: "#ee2c46" }}>404 - העמוד לא נמצא</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom select-detail-universal-bac-bottum"></div>
      </div>
    </>
  );
}
