import React, { useState, useEffect, useRef } from "react";
// import mainsignin from "../../../Common/Assets/Images/signin.png";
import topBtnBg from "../../../Common/Assets/Images/smsotpbg.png";
import OtpInput from "react-otp-input";

import { useNavigate } from "react-router-dom";
import { signInWithPhoneNumber, RecaptchaVerifier,signInWithCustomToken } from "firebase/auth";
import app, { appAuthentication, baseApiUri, apiRouteSignInWithMatchersAccount,apiRouteVerifyPhoneNumber } from "../../../Firebase/Firebase";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

import {
  ASSOCIATED_PROFILES,
  LHS_KEY,
  MATCHER_ID,
  MATCH_REJECT,
  MOBLIE_NUMBER,
  PERSONANLITY_DATA,
  REJECTRESON_DATA,
  RHS_KEY,
  SAVE_TERMS,
  SINGLES_ID,
  SINGLE_DOCID,
  SINGLE_GEN,
} from "../../../Redux/constants";
import { Modal } from "react-bootstrap";
import { setLogcat } from "../../../Common/set_logcat";

var sdid = "";

export default function Smsotp() {
  const dispatch = useDispatch();

  const { otpConfirmationResult, phoneNumberToBeVerify, spid, lrmpid } =
    useSelector((state) => state);
  let phoneNumber = phoneNumberToBeVerify;
  let otpConfirmation = otpConfirmationResult;

  const [show, setShow] = useState(false);

  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [error, setError] = useState("");
  const [timer, setTimer] = useState("");
  const naviagte = useNavigate();

  const firestore = getFirestore(app);

  const handleOtpChange = (otp) => setOtp(otp);

  const recaptchaWrapperRef = useRef();

  const userType = "single";

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      appAuthentication
    );
  };

  const verifyOtp = () => {
    setErrorMessage(false);
    if (otp.length != 6) {
      setError("הקוד לא תקין, אפשר לנסות שוב");
      setErrorMessage(true);
      return true;
    }

    if (phoneNumber == null || phoneNumber == "") {
      setError("הקוד לא תקין, אפשר לנסות שוב");
      setErrorMessage(true);
      return true;
    }

    setShow(true);

    const signinLocalUrl = `${baseApiUri}${apiRouteSignInWithMatchersAccount}?userType=${userType}`;
    const data = {
      "smsCode": otp,
      "verificationId": String(otpConfirmation)
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*'
      },
      body: formBody
    };

    otpConfirmation &&
    fetch(signinLocalUrl, requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 400) {
            throw new Error("invalid-verification-code");
          }
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(async (response) => {
        signInWithCustomToken(appAuthentication, response.access_token)
          .then(async () => {
          if (spid !== null && spid !== "") {
            const querySnapshot = await getDocs(
              query(
                collection(firestore, "single_profiles"),
                where("phone_number", "==", phoneNumber)
              )
            );

            if (querySnapshot.docs.length > 0) {
              doesDocExist();
            } else {
              naviagte("/personalsplash");
            }
          } else {
            const querySnapshot = await getDocs(
              query(
                collection(firestore, "singles"),
                where("phone_number", "==", phoneNumber)
              )
            );

            if (querySnapshot.docs.length > 0) {
              if (lrmpid !== null && lrmpid !== "") {
                checkUser(lrmpid.charAt(0), lrmpid.substring(2));
              } else {
                window.location.replace("/");
              }
            } else {
              window.location.replace("/personalsplash");
            }
          }
        })
        .catch((error) => {
          setShow(false);

          setError(error.message);

          if (error.message.includes("invalid-verification-code")) {
            setError("קוד אימות לא תקף");
          }

          if (error.message.includes("code-expired")) {
            setError("תוקף קוד האימות פג");
          }

          setErrorMessage(true);
          return true;
        });
  })
  .catch((error) => {
    setShow(false);

    setError(error.message);

    if (error.message.includes("invalid-verification-code")) {
      setError("קוד אימות לא תקף");
    }

    if (error.message.includes("code-expired")) {
      setError("תוקף קוד האימות פג");
    }

    setErrorMessage(true);
    return true;
  });
};

  // get data from firebase this side

  const doesDocExist = async () => {
    const firestore = getFirestore(app);
    const docSnap = await getDoc(doc(firestore, "single_profiles", spid));

    if (docSnap.exists()) {

        dispatch({
          type: SINGLE_GEN,
          payload: docSnap.get("gender"),
        });


      if (docSnap.get("phone_number") == phoneNumber) {
        
        const lookingPersonalityTraits = await getDocs(
          query(collection(firestore, "personality_traits"))
        );

        // send lookingPersonalityTraits with dispatch

        dispatch({
          type: PERSONANLITY_DATA,
          payload: lookingPersonalityTraits.docs,
        });

        // send MOBLIE_NUMBER with dispatch

        dispatch({
          type: MOBLIE_NUMBER,
          payload: docSnap.get("phone_number"),
        });

        sdid = docSnap.get("single_id");

        dispatch({
          type: SINGLE_DOCID,
          payload: docSnap.get("single_id"),
        });

        // send matcher_id from single_profiles with dispatch

        dispatch({ type: MATCHER_ID, payload: docSnap.get("matcher_id") });

        const querySnapshot = await getDocs(
          query(
            collection(firestore, "singles"),
            where("phone_number", "==", docSnap.get("phone_number"))
          )
        );

        // send document id from singles collection with dispatch

        dispatch({
          type: SINGLES_ID,
          payload:
            querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : "",
        });

        // send associated_profile in array from singles collection with dispatch

        dispatch({
          type: ASSOCIATED_PROFILES,
          payload:
            querySnapshot.docs.length > 0
              ? querySnapshot.docs[0].get("associated_profiles") != null
                ? querySnapshot.docs[0].get("associated_profiles")
                : []
              : [],
        });

        if (sdid != null && sdid != "") {
          window.location.replace(`/?id=${spid}`);
        } else {
          if (querySnapshot.docs.length > 0) {
            naviagte(`/check-details?id=${spid}`);
          } else {
            naviagte(`/user-details-select?id=${spid}`);
          }
        }
      } else {
        naviagte("/personalsplash");
      }
    } else {
      naviagte("/authentication");
    }
  };

  // get single id fo singles page
  const checkUser = async (lr, pid) => {
    const proposalSnap = await getDoc(doc(firestore, "proposals", pid));

    if (proposalSnap.exists()) {
      // forword lhs and rhs key with dispatch function

      var check = false;

      if (
        lr == "l" &&
        proposalSnap.get("lhs_single_phone_number") == phoneNumber
      ) {
        check = true;
      } else if (
        lr == "r" &&
        proposalSnap.get("rhs_single_phone_number") == phoneNumber
      ) {
        check = true;
      }

      if (check) {

        const rejectReasonData = await getDocs(
          query(collection(firestore, "single_reject_reason"))
        );

        dispatch({
          type: REJECTRESON_DATA,
          payload: rejectReasonData.docs,
        });

        dispatch({
          type: LHS_KEY,
          payload: proposalSnap.get("lhs_matcher"),
        });
        dispatch({
          type: RHS_KEY,
          payload: proposalSnap.get("rhs_matcher"),
        });

        dispatch({
          type: MATCH_REJECT,
          payload: proposalSnap.get("match_reject_reason") >= 0,
        });

        // window.location.replace("/?lrpid=" + lrmpid);
        naviagte(`/?lrpid=${lrmpid}`);
      } else {
        naviagte("/personalsplash");
      }
    } else {
      naviagte("/authentication");
    }
  };

  const resendOtp = () => {
    setErrorMessage(false);
    if (phoneNumber === undefined) {
      setError("שגיאת שרת פנימית לא יכולה לעבד את הבקשה אנא נסה לאחר זמן מה");
      setErrorMessage(true);
      return false;
    }

    if (phoneNumber === "" || phoneNumber === null || phoneNumber.length < 10) {
      setError("השליחה מחדש של otp במספר הטלפון נכשלה");
      setErrorMessage(true);
      return false;
    }

    console.log("phoneNumber : ", phoneNumber.replace("-", ""));
    const backendUrl = `${baseApiUri}${apiRouteVerifyPhoneNumber}?phoneNumber=${phoneNumber.replace('-','')}&userType=${userType}`;
    fetch(backendUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
      .then((verifyPhoneNumberResponseData) => {
        const confirmationResult = verifyPhoneNumberResponseData.verification_id;
        otpConfirmation = confirmationResult;
        console.log("ConfirmationResult : ", confirmationResult);

        setLogcat(
          "SignInLogs",
          "INFO",
          "(" +
            phoneNumber +
            ") ConfirmationResult : " +
            JSON.stringify(confirmationResult)
        );

        window.confirmationResult = confirmationResult;

        dispatch({
          type: otpConfirmationResult,
          payload: otpConfirmation,
        });

        otpTimer();
      })
      .catch((error) => {
        setError(error.message);
        if (error.message.includes("internal-error")) {
          setError("שגיאת שרת פנימית");
        }
        if (
          error.message.includes("network-request-failed") ||
          error.message.includes("too-request-failed")
        ) {
          setError("בקשת הרשת נכשלה");
        }
        if (error.message.includes("too-many-requests")) {
          setError("הוגשו יותר מדי בקשות, אנא נסה שוב מאוחר יותר");
        }
        setErrorMessage(true);

        return false;
      });
  };

  const otpTimer = () => {
    var t = 60;

    const intervalID = setInterval(() => {
      if (t > 0) {
        t--;
        setTimer(
          new Intl.DateTimeFormat("en-GB", {
            timeZone: "Etc/UTC",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(t * 1000))
        );
      } else {
        setTimer("00:00");
        clearInterval(intervalID);
      }
    }, 1000);
  };

  useEffect(() => {
    if (phoneNumber == null) {
      naviagte("/");
    }

    otpTimer();
  }, []);

  return (
    <>
     <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid home-universal-container-box">
        <div className="main-home smsotp-main-home-box">
          <div className="container ">
            <div className="row universal-row-box home-universal-row-box">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row home-header-box all-data-center-box">
                    <img
                      className="img-fluid universal-logo universal-logo-top-distance"
                      alt="logo"
                      src="./images/logo.png"
                    />
                  </div>
                  <div
                    className={
                      "row universal-center-box-data signin-screen-custum-design-row"
                    }
                  >
                    <div className="smsotp-input-box-top-text universal-center-box-data">
                      <h2>כניסה עם קוד חד-פעמי ב-SMS</h2>
                      <p>
                        {"שלחנו קוד חד-פעמי לנייד שמסתיים ב- " +
                          phoneNumber.substring(phoneNumber.length - 4)}
                      </p>
                    </div>

                    <div className="smsotp-screen-custum-design-img-box universal-center-box-data">
                      <h2>הקוד שקיבלת ב-SMS</h2>
                    </div>

                    <div
                      className={`universal-center-box-data smsotp-screen-custum-design-input-box ${
                        !errorMessage
                          ? "smsotp-screen-custum-design-input-box"
                          : "smsotp-screen-custum-design-input-box-err"
                      }`}
                    >
                      <OtpInput
                        isInputNum={true}
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        separator={<span></span>}
                        inputStyle={{
                          width: "10 px",
                          height: "10 px",
                          fontSize: "1rem",
                        }}
                      />
                      <div className="verify-otp-error-box-container">
                        {errorMessage && (
                          <div className="error-message-box-signin">
                            {error}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="smsotp-screen-convert-english universal-center-box-data">
                      {timer === "00:00" ? (
                        <h4 className="">
                          לא קיבלת SMS?{" "}
                          <a href="#" onClick={resendOtp} className="">
                            שילחו שוב
                          </a>{" "}
                        </h4>
                      ) : (
                        <h4 className="">{timer}</h4>
                      )}
                    </div>
                    <div className="universal-background-top-btn select-detail-universal-bac-top-btn">
                      <img
                        className="universal-background-top-btn-img"
                        src={topBtnBg}
                        alt="logo"
                      />
                    </div>
                    <div className="signin-screen-custum-design-input-button-box universal-center-box-data">
                      <button
                        type="button"
                        className="btn btn-primary smsotp-button-box"
                        onClick={verifyOtp}
                        // style={{
                        //   cursor: "pointer",
                        //   zIndex: "596564876549686589546989658",
                        // }}
                      >
                        התחברות
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={recaptchaWrapperRef}>
          <div id="recaptcha-container"></div>
        </div>
      </div>
    </>
  );
}
