import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import select_lifestyle from "../../Common/Assets/Images/select_lifestyle.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LIFESTYLE_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

import lifestyle_male_1 from "../../Common/Assets/background/lifestyle_male_1.png";
import lifestyle_male_2 from "../../Common/Assets/background/lifestyle_male_2.png";
import lifestyle_male_3 from "../../Common/Assets/background/lifestyle_male_3.png";
import lifestyle_male_4 from "../../Common/Assets/background/lifestyle_male_4.png";
import lifestyle_male_5 from "../../Common/Assets/background/lifestyle_male_5.png";
import lifestyle_male_6 from "../../Common/Assets/background/lifestyle_male_6.png";
import lifestyle_female_1 from "../../Common/Assets/background/lifestyle_female_1.png";
import lifestyle_female_2 from "../../Common/Assets/background/lifestyle_female_2.png";
import lifestyle_female_3 from "../../Common/Assets/background/lifestyle_female_3.png";
import lifestyle_female_4 from "../../Common/Assets/background/lifestyle_female_4.png";
import lifestyle_female_5 from "../../Common/Assets/background/lifestyle_female_5.png";
import lifestyle_female_6 from "../../Common/Assets/background/lifestyle_female_6.png";

const SelectLifestyle = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [lifestyle_options, setLifestyleOptions] = useState(-1);

  const { single_gen, single_looking_gen } = useSelector((state) => state);

  var gender =
  sid != null && sid != "" ? (single_looking_gen == 0 ? 1 : 0) : single_gen;

  const [backgroundImage, setBackgroundImage] = useState(
    gender == 0 ? lifestyle_male_1 : lifestyle_female_1
  );

  const religiousViewData = [
    { english: "Modern-orthodox", hebrew: "דתי בראש טוב" },
    { english: "Orthodox", hebrew: "דתי-לאומי" },
    { english: "Ultra-orthodox", hebrew: "דתי-תורני" },
    { english: "Traditional", hebrew: "מסורתי" },
    { english: "Secular", hebrew: "חילוני" },
    { english: "Ex-religious", hebrew: "דתל״ש" },
  ];

  const [show, setShow] = useState(false);

  const firestore = getFirestore(app);

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("", "", "");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("", "", "");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));
    if (docSnap.exists) {
      const religious_view = docSnap.get("religious_view");
      if (religious_view != null) {
        backgroungForReligiousView(religious_view);
        setLifestyleOptions(religious_view);
      }
    }
    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    console.log("lifestyle_options", lifestyle_options);

    if (lifestyle_options != -1) {
      dispatch({ type: LIFESTYLE_DATA, payload: lifestyle_options });

      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          religious_view: lifestyle_options,
          last_updated: serverTimestamp(),
        });
      }

      if (linkId != null && linkId != "") {
        Navigate(`/select-politics?id=${linkId}`);
      } else {
        Navigate(`/select-politics?sid=${sid}`);
      }
    }
  };

  const backgroungForReligiousView = (religiousView) => {
    if (religiousView == -1) {
      if (gender == 0) {
        setBackgroundImage(lifestyle_male_4);
      } else if (gender == 1) {
        setBackgroundImage(lifestyle_female_4);
      }
    } else {
      if (gender == 0) {
        if (religiousView == 0) {
          setBackgroundImage(lifestyle_male_1);
        } else if (religiousView == 1) {
          setBackgroundImage(lifestyle_male_2);
        } else if (religiousView == 2) {
          setBackgroundImage(lifestyle_male_3);
        } else if (religiousView == 3) {
          setBackgroundImage(lifestyle_male_4);
        } else if (religiousView == 4) {
          setBackgroundImage(lifestyle_male_5);
        } else if (religiousView == 5) {
          setBackgroundImage(lifestyle_male_6);
        }
      } else if (gender == 1) {
        if (religiousView == 0) {
          setBackgroundImage(lifestyle_female_1);
        } else if (religiousView == 1) {
          setBackgroundImage(lifestyle_female_2);
        } else if (religiousView == 2) {
          setBackgroundImage(lifestyle_female_3);
        } else if (religiousView == 3) {
          setBackgroundImage(lifestyle_female_4);
        } else if (religiousView == 4) {
          setBackgroundImage(lifestyle_female_5);
        } else if (religiousView == 5) {
          setBackgroundImage(lifestyle_female_6);
        }
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home location occupation life_style"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine location"
                  src={Logo}
                  alt=""
                />
              </div>

              {/* <div class="border-line">
                <div></div>
                <div class="second"></div>
                <div class="third"></div>
                <div class="forth"></div>
              </div> */}

              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div  logo-life">
                <p className="text-1 gender-1-text location lifestyle">
                  <b>{"אורח חיים"}</b>
                </p>
              </div>

              <ul className="list-btn-data-box life-style">
                {religiousViewData.map((item, index) => {
                  return (
                    <li
                      key={index}
                      id="list_item"
                      onClick={() => {
                        setLifestyleOptions(index);
                        backgroungForReligiousView(index);
                      }}
                      style={{
                        background:
                          lifestyle_options == index ? "#EE2C46" : "white",
                        color: lifestyle_options == index ? "white" : "#9A9A9A",
                        border:
                          lifestyle_options == index
                            ? "1px solid #EE2C46"
                            : "1px solid #D9D9D9",
                      }}
                    >
                      <a>{item["hebrew"]}</a>
                    </li>
                  );
                })}
              </ul>

              <section class="location">
                <div class="container">
                  <div location-wrap></div>
                </div>
              </section>

              <div className="image-group1 location-btn lifestyle">
                <div className="button-div btn-change">
                  <button
                    className="button-accept profile-button"
                    style={
                      lifestyle_options != -1
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "grey" }
                    }
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectLifestyle;
