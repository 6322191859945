import React, { useEffect, useState } from "react";
import Picker from "react-scrollable-picker";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
// import select_age from "../../Common/Assets/Images/select-age.png"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";
import { AGE_DATA } from "../../Redux/constants";

import age_male_22 from "../../Common/Assets/background/age_male_22.png";
import age_male_26 from "../../Common/Assets/background/age_male_26.png";
import age_male_40 from "../../Common/Assets/background/age_male_40.png";
import age_female_22 from "../../Common/Assets/background/age_female_22.png";
import age_female_26 from "../../Common/Assets/background/age_female_26.png";
import age_female_40 from "../../Common/Assets/background/age_female_40.png";

const SelectAge = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [age, setAge] = useState("22");

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [show, setShow] = useState(false);

  const { single_gen, single_looking_gen } = useSelector((state) => state);

  var gender =
    sid != null && sid != "" ? (single_looking_gen == 0 ? 1 : 0) : single_gen;

  const [backgroundImage, setBackgroundImage] = useState(
    gender == 0 ? age_male_22 : age_female_22
  );

  const firestore = getFirestore(app);

  const [data, setData] = useState({
    valueGroups: {
      title: "Age",
    },
    // default value set
    optionGroups: {
      title: [{ value: "22", label: "22" }],
    },
  });

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    var _arr = [];
    for (let i = 99; i >= 18; i--) {
      _arr.push({ label: i.toString(), value: i.toString() });
    }

    var dt = { ...data };
    dt.optionGroups = { title: _arr };

    setData(dt);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("overflow", "hidden", "important");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("overflow", "visible", "important");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const birth_year = docSnap.get("birth_year");

      if (birth_year != null) {
        backgroungForAge(new Date().getFullYear() - birth_year);

        setData();

        var dt = { ...data };
        dt.optionGroups = {
          title: [
            {
              label: (new Date().getFullYear() - birth_year).toString(),
              value: (new Date().getFullYear() - birth_year).toString(),
            },
          ],
        };

        setData(dt);
      }
    }

    // setShow(false);
  };

  const handleChange = async (name, value) => {
    setAge(value);

    var dt = { ...data };
    dt.valueGroups = {
      ...dt.valueGroups,
      [name]: value,
    };
    setData(dt);

    backgroungForAge(parseInt(value));
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    var age_option = parseInt(age);

    console.log("age", age_option);

    dispatch({ type: AGE_DATA, payload: age_option });

    if (sid != null && sid != "") {
      await updateDoc(doc(collection(firestore, "singles"), sid), {
        birth_year: new Date().getFullYear() - age_option,
        last_updated: serverTimestamp(),
      });
    }

    if (linkId != null && linkId != "") {
      Navigate(`/select-location?id=${linkId}`);
    } else {
      Navigate(`/select-location?sid=${sid}`);
    }
  };

  const backgroungForAge = (value) => {
    console.log("gender", gender);

    if (gender == 0) {
      if (value >= 18 && value < 26) {
        setBackgroundImage(age_male_22);
      } else if (value >= 26 && value < 41) {
        setBackgroundImage(age_male_26);
      } else if (value >= 41 && value <= 99) {
        setBackgroundImage(age_male_40);
      }
    } else if (gender == 1) {
      if (value >= 18 && value < 26) {
        setBackgroundImage(age_female_22);
      } else if (value >= 26 && value < 41) {
        setBackgroundImage(age_female_26);
      } else if (value >= 41 && value <= 99) {
        setBackgroundImage(age_female_40);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home age"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="universal-background-top ">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height age">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine select-age-logo"
                  src={Logo}
                  alt=""
                />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text">
                  <b>{"גיל"}</b>
                </p>
              </div>

              <div className="change-age">
                <Picker
                  optionGroups={data.optionGroups}
                  valueGroups={data.valueGroups}
                  onChange={handleChange}
                  height={270}
                  smooth={true}
                />
              </div>

              <div className="image-group1 age">
                <div className="button-div">
                  <button
                    className="button-accept profile-button"
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectAge;
