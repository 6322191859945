import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import female from "../../Common/Assets/Images/female.png";
import male from "../../Common/Assets/Images/male.png";
import maleG from "../../Common/Assets/Images/Male-color.png";
import female1 from "../../Common/Assets/Images/female1.png";
import maleFemale from "../../Common/Assets/Images/malefemale.png";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";

import { Modal } from "react-bootstrap";
import { SINGLE_LOOKING_GEN, USER_GENDER } from "../../Redux/constants";
import { useDispatch, useSelector } from "react-redux";

import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";

export default function UserGender() {
  const [state, setState] = useState(-1);
  const [show, setShow] = useState(false);
  const [msgPopup, setMsgPopup] = useState(false);

  const firestore = getFirestore(app);

  // const[ selectGender,setSelectGender]=useState(null)
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const dispatch = useDispatch();

  const selectGen = async () => {

    setMsgPopup(false);
    setShow(true);

    dispatch({ type: USER_GENDER, payload: state });

    console.log("gender", state);

    if (sid != null && sid != "") {
      await updateDoc(doc(collection(firestore, "singles"), sid), {
        looking_for_gender: state,
        last_updated: serverTimestamp(),
      });

      dispatch({
        type: SINGLE_LOOKING_GEN,
        payload: state,
      });
    }

    setShow(false);

    if (linkId != null && linkId != "") {
      Navigate(`/select-agerange?id=${linkId}`);
    } else {
      Navigate(`/select-agerange?sid=${sid}`);
    }
  };



  /**
   * ----------------------------------------------------------------
   * gender change event handlers start
   * ----------------------------------------------------------------
   */
  const handleGenderChange = (event) => {
    const selectedAttr = event.target.getAttribute("data-id");

    if (selectedAttr === "male") {
      setState(0);
    } else if (selectedAttr === "female") {
      setState(1);
    }
  };
  /**
   * ----------------------------------------------------------------
   * gender change event handlers end
   * ----------------------------------------------------------------
   */
  useEffect(() => {
    window.scrollTo(0, 0);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.overflow = "visible";
  }, []);

  const getData = async () => {
    setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));
    const gender = docSnap.get("looking_for_gender");
    setState(gender);
    setShow(false);
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div className="main-home user-gender">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row universal-row-box">
            <div className="col-12 matchup-col">
              <div className="logo-div">
                <img className="universal-logo" src={Logo} alt="" />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div
                    className="col-1 indicator-blank"
                    style={{
                      backgroundColor: state != -1 ? "#EE2C46" : null,
                    }}
                  ></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>

                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text">
                  {t("usergander-page-title")}
                </p>
              </div>

              <div className="gender-icon">
                <img
                  alt=""
                  data-id="female"
                  onClick={(e) => {
                    handleGenderChange(e);
                  }}
                  className="icon-gende"
                  src={state == 1 ? female1 : female}
                />

                <img
                  alt=""
                  data-id="male"
                  onClick={(e) => {
                    handleGenderChange(e);
                  }}
                  className="icon-gende"
                  src={state == 0 ? maleG : male}
                />
              </div>

              <div className="image-group1">
                <div className="button-div">
                  <button
                    style={{
                      backgroundColor: state != -1 ? "#EE2C46" : null,
                    }}
                    className="button-accept profile-button-box"
                    onClick={() => {
                      if (state != -1) {
                        setMsgPopup(true);
                      }
                    }}
                  >
                    {t("usergender-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>

      {msgPopup && (
        <>
          <div className="popup-2">
            <div className="popup-good popup-existing-profile "></div>
            <div
              className="modal d-block splash-auto-open-popup"
              onClick={() => {}}
            >
              <div className="modal-dialog success-modal">
                <div className="modal-content success-modal-content">
                  <div className="modal-body success-modal-body">
                    <img
                      src={maleFemale}
                      className="malefemale-icon"
                      alt="icon"
                    />

                    <h2 className="success-title-top2">
                      {t("malefemale-heading")}
                    </h2>

                    <p className="user-gender malefemale-text">
                      <span>{t("malefemale-text")}</span>
                    </p>

                    <button
                      className="button-exit-success "
                      onClick={() => {
                        selectGen();
                      }}
                    >
                      {t("malefemale-btn-1")}
                    </button>
                    <button
                      className="malefemale-btn-2_outline"
                      onClick={() => {
                        setMsgPopup(false);
                      }}
                    >
                      {t("malefemale-btn-2")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
