import React, { useEffect, useRef, useState } from "react";
import whitelogo from "../../../Common/Assets/Images/whitelogo.png";
import HeartPopup from "../../HeartPopup";
import "../../../Common/CSS/Style.css";
import closeicon from "../../../Common/Assets/Images/close-icone-popup.png";
import { useTranslation } from "react-i18next";
import pluse from "../../../Common/Assets/Images/plus.png";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp as serverTimestamp2,
  updateDoc,
} from "firebase/firestore";

import app from "../../../Firebase/Firebase";
import { Modal } from "react-bootstrap";
import { LRPID } from "../../../Redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { getDatabase, push, ref, serverTimestamp } from "firebase/database";
import moment from "moment";
import popupBecomeMatcherImg from "../../../Common/Assets/Images/popupBecomeMatcherImg.png";
import appstore from "../../../Common/Assets/Images/appstore.png";
import googleplay from "../../../Common/Assets/Images/googleplay.png";

// this globle for get singles name and add in realtime database

var lhs_single_name = "";
var rhs_single_name = "";
var ExpairTime;
var extraInfoRecorded = "";
var audio;
var myInterval;
var csid;
var lcsid;
var rcsid;

export default function SingleProfiles() {
  //For Production
  const serverKey =
    "AAAATTeQZog:APA91bHI2uS28E1p-EDmiX3dt92A6p-FyMkoU3UA-GZ9QcR7mt3dXGMsUKZWSc1-_XIHqFRjtikWg66GMj648EbJPjvMlPbTUK8hKEIeLhZwATZnUudw6ZwzJSFzx7Z3VVcCuaq-2aX3";

  // //For Development
  // const serverKey =
  //   "AAAAwVT29IA:APA91bETbpTfISpKzO1wDlTxe780c43wEf7shFOwpod7kh3GghbA9a7ALlN5ZyJYAqJmqbGFUfe59v0JdQpolgSH0KR-TaIVRWM42b49KM39K364OkOmiCstxjg7hlbm45SN6E3NOBdg";

  const religiousViewData = [
    { english: "Modern-orthodox", hebrew: "דתי בראש טוב" },
    { english: "Orthodox", hebrew: "דתי-לאומי" },
    { english: "Ultra-orthodox", hebrew: "דתי-תורני" },
    { english: "Traditional", hebrew: "מסורתי" },
    { english: "Secular", hebrew: "חילוני" },
    { english: "Ex-religious", hebrew: "דתל״ש" },
  ];

  const locationData = [
    { english: "Tel-Aviv/Center", hebrew: "תל-אביב/מרכז" },
    { english: "Jerusalem and surroundings", hebrew: "ירושלים והסביבה" },
    { english: "North", hebrew: "צפון" },
    { english: "South", hebrew: "דרום" },
  ];

  const { t } = useTranslation();
  const [imageUrls, setImageUrls] = useState([]);
  const [name, setName] = useState("");
  const [age, setAge] = useState(0);
  const [location, setLocation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [lifeStyle, setLifeStyle] = useState("");
  const [lookingPersonality, setLookingPersonality] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setloader] = useState(false);
  const [time, setTime] = useState("00.00.00");
  const Navigate = useNavigate();
  const firestore = getFirestore(app);
  const dispatch = useDispatch();
  const [PleaseWait, setPleaseWait] = useState(false);
  const [lhsState, setLhsState] = useState(0);
  const [rhsState, setRhsState] = useState(0);
  const { match_reject, lhs_keyid, rhs_keyid } = useSelector((state) => state);
  const [playButtonClass, setPlayButtonClass] = useState(
    "bi bi-play-circle-fill"
  );
  const [audioDuration, setAudioDuration] = useState("00:00");
  const [matcherData, setMatcherData] = useState({
    matcherName: "",
    matcherImage: "",
  });
  const refCarouselIndicators = useRef();

  const [showDialog, setShowDialog] = useState(false);

  // add aduio play this side
  const playPause = () => {
    if (audio != null) {
      if (!audio.paused) {
        setPlayButtonClass("bi bi-play-circle-fill");
        audio.pause();
      } else {
        setPlayButtonClass("bi bi-pause-circle-fill");
        audio.play();
      }
    }
  };

  const setTimer = (target) => {
    var t = target.duration - target.currentTime;

    myInterval = setInterval(() => {
      if (t > 0) {
        t--;
        setAudioDuration(new Date(t * 1000).toISOString().substring(11, 19));
      }
    }, 1000);
  };

  const lr_pid = new URLSearchParams(window.location.search).get("lrpid");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "visible";

    if (match_reject) {
      setloader(true);
      Navigate(`/proposalIrrelevant?lrpid=${lr_pid}`);
    }

    if (lr_pid !== null && lr_pid !== "") {
      dispatch({ type: LRPID, payload: lr_pid });
      doesDocExist(lr_pid.charAt(0), lr_pid.substring(2));
    }
    setloader(true);
  }, []);

  useEffect(() => {
    if (imageUrls.length > 0) {
      if (refCarouselIndicators.current.firstChild != null) {
        refCarouselIndicators.current.firstChild.click();
      }
    }
  }, [imageUrls]);

  // get all information on this function like:- image, name, location, personality, audio, occupation

  const doesDocExist = async (lr, pid) => {
    setloader(true);

    const proposalSnap = await getDoc(doc(firestore, "proposals", pid));

    if (proposalSnap.exists()) {
      setLhsState(proposalSnap.get("lhs_current_state"));
      setRhsState(proposalSnap.get("rhs_current_state"));

      const rdocSnap = await getDoc(
        doc(
          firestore,
          "single_profiles",
          proposalSnap.get("rhs_single_profile")
        )
      );
      const ldocSnap = await getDoc(
        doc(
          firestore,
          "single_profiles",
          proposalSnap.get("lhs_single_profile")
        )
      );

      lhs_single_name = ldocSnap.get("full_name");
      rhs_single_name = rdocSnap.get("full_name");

      var sDocSnap, mid;

      lcsid = proposalSnap.get("lhs_single");
      rcsid = proposalSnap.get("rhs_single");

      const y = new Date().getFullYear();

      if (lr === "l") {
        csid = lcsid;
        mid = proposalSnap.get("lhs_matcher");

        sDocSnap = await getDoc(
          doc(firestore, "singles", proposalSnap.get("rhs_single"))
        );

        if (rdocSnap.exists()) {
          setName(rdocSnap.get("full_name"));

          if (rdocSnap.get("birth_year") != null) {
            setAge(y - rdocSnap.get("birth_year"));
          } else {
            setAge(y - sDocSnap.get("birth_year"));
          }

          if (rdocSnap.get("location") != null) {
            setLocation(locationData[rdocSnap.get("location")].hebrew);
          } else {
            setLocation(locationData[sDocSnap.get("location")].hebrew);
          }

          if (rdocSnap.get("occupation") != null) {
            setOccupation(rdocSnap.get("occupation"));
          } else {
            setOccupation(sDocSnap.get("occupation"));
          }

          setExtraInfo(rdocSnap.get("extra_info"));

          if (rdocSnap.get("religious_view") != null) {
            setLifeStyle(
              religiousViewData[rdocSnap.get("religious_view")].hebrew
            );
          } else {
            setLifeStyle(
              religiousViewData[sDocSnap.get("religious_view")].hebrew
            );
          }

          extraInfoRecorded = rdocSnap.get("extra_info_recorded");

          var ids = rdocSnap.get("personality_traits");

          var dSnap = await Promise.all(
            ids.map(
              async (item, index) =>
                await getDoc(doc(firestore, "personality_traits", item))
            )
          );

          setLookingPersonality(dSnap);
        }
      } else if (lr === "r") {
        csid = rcsid;
        mid = proposalSnap.get("rhs_matcher");

        sDocSnap = await getDoc(
          doc(firestore, "singles", proposalSnap.get("lhs_single"))
        );

        if (ldocSnap.exists()) {
          setName(ldocSnap.get("full_name"));

          if (ldocSnap.get("birth_year") != null) {
            setAge(y - ldocSnap.get("birth_year"));
          } else {
            setAge(y - sDocSnap.get("birth_year"));
          }

          if (ldocSnap.get("location") != null) {
            setLocation(locationData[ldocSnap.get("location")].hebrew);
          } else {
            setLocation(locationData[sDocSnap.get("location")].hebrew);
          }

          if (ldocSnap.get("occupation") != null) {
            setOccupation(ldocSnap.get("occupation"));
          } else {
            setOccupation(sDocSnap.get("occupation"));
          }

          setExtraInfo(ldocSnap.get("extra_info"));

          if (ldocSnap.get("religious_view") != null) {
            setLifeStyle(
              religiousViewData[ldocSnap.get("religious_view")].hebrew
            );
          } else {
            setLifeStyle(
              religiousViewData[sDocSnap.get("religious_view")].hebrew
            );
          }

          extraInfoRecorded = ldocSnap.get("extra_info_recorded");

          // console.log(ldocSnap.get("extra_info_recorded") === "");
          const ids = ldocSnap.get("personality_traits");

          const dSnap = await Promise.all(
            ids.map(
              async (item, index) =>
                await getDoc(doc(firestore, "personality_traits", item))
            )
          );

          setLookingPersonality(dSnap);
        }
      }

      audio = new Audio(extraInfoRecorded);
      audio.addEventListener("loadedmetadata", (e) => {
        setAudioDuration(
          new Date(e.target.duration * 1000).toISOString().substring(11, 19)
        );
      });

      audio.addEventListener("play", (e) => {
        setPlayButtonClass("bi bi-pause-circle-fill");
        setTimer(e.target);
      });

      audio.addEventListener("pause", (e) => {
        setPlayButtonClass("bi bi-play-circle-fill");

        if (myInterval != null) {
          clearInterval(myInterval);
        }
      });

      audio.addEventListener("ended", (e) => {
        console.log(e.target.duration);

        setPlayButtonClass("bi bi-play-circle-fill");

        if (myInterval != null) {
          clearInterval(myInterval);
        }

        setAudioDuration(
          new Date(e.target.duration * 1000).toISOString().substring(11, 19)
        );
      });

      const mDocSnap = await getDoc(doc(firestore, "matchers", mid));

      mDocSnap.exists() &&
        setMatcherData({
          matcherName: mDocSnap.get("full_name"),
          matcherImage: mDocSnap.get("profile_picture"),
        });

      if (sDocSnap.exists()) {
        setImageUrls(sDocSnap.get("profile_pictures"));
      }

      setloader(false);

      if (lr === "l") {
        if (proposalSnap.get("lhs_current_state") === 2) {
          setShow(true);
          setPleaseWait(true);
        }
      } else if (lr === "r") {
        if (proposalSnap.get("rhs_current_state") === 2) {
          setShow(true);
          setPleaseWait(true);
        } else {
          setPleaseWait(false);
        }
      }

      // ExpairTime = proposalSnap.get("single_expiry_date");
      // var timeExp = ExpairTime.seconds;
      // var currentTime = Math.floor(new Date().getTime() / 1000);
      // if (currentTime < timeExp) {
      //   var t = timeExp - currentTime;
      //   setInterval(() => {
      //     if (t > 0) {
      //       t--;
      //       setTime(toHHMMSS(t));
      //     } else {
      //       setTime("Time is up");
      //     }
      //   }, 1000);
      // } else {
      //   Navigate(`/proposalIrrelevant?lrpid=${lr_pid}`);
      // }
    }
  };

  // const toHHMMSS = (secs) => {
  //   var sec_num = parseInt(secs, 10);
  //   var hours = Math.floor(sec_num / 3600);
  //   var minutes = Math.floor(sec_num / 60) % 60;
  //   var seconds = sec_num % 60;

  //   return [hours, minutes, seconds]
  //     .map((v) => (v < 10 ? "0" + v : v))
  //     .filter((v, i) => v !== "00" || i > 0)
  //     .join(":");
  // };

  const acceptDate = async () => {
    // if (ExpairTime != null) {
    //   var timeExp = ExpairTime.seconds;
    //   var currentTime = Math.floor(new Date().getTime() / 1000);

    //   if (currentTime > timeExp) {
    //     Navigate(`/proposalIrrelevant?lrpid=${lr_pid}`);
    //   } else {
        setloader(true);

        const database = getDatabase(app);

        const proposalSnap = await getDoc(
          doc(firestore, "proposals", lr_pid.substring(2))
        );

        if (
          proposalSnap.get("is_active") == 0 ||
          proposalSnap.get("match_reject_reason") >= 0
        ) {
          setloader(false);
          Navigate(`/proposalIrrelevant?lrpid=${lr_pid}`);
        } else {
          if (lr_pid.charAt(0) === "l") {
            await updateDoc(
              doc(collection(firestore, "proposals"), lr_pid.substring(2)),
              {
                lhs_current_state: 2,
                updated_at: serverTimestamp2(),
              }
            );

            // this l side // real time data base for chat messages

            await push(
              ref(
                database,
                "conversations/" +
                  proposalSnap.get("conversation_id") +
                  "/messages/"
              ),
              {
                sender_id: lhs_keyid,
                content: JSON.stringify({
                  english: lhs_single_name + " is in for a date...",
                  hebrew: lhs_single_name + " רוצה דייט",
                }),
                message_type: 4,
                read_status: 0,
                timestamp: serverTimestamp(),
              }
            );

            setShow(true);

            setTimeout(() => {
              setShowDialog(true);
            }, 1500);

            if (rhsState === 2) {
              // When both of matcher accept then some sec after redirect to page  match-success
              await updateDoc(doc(collection(firestore, "singles"), rcsid), {
                is_active: false,
                last_updated: serverTimestamp2(),
              });
              await updateDoc(doc(collection(firestore, "singles"), lcsid), {
                is_active: false,
                last_updated: serverTimestamp2(),
              });

              postapi();
            } else {
              const rhsMatcher = await getDoc(
                doc(firestore, "matchers", rhs_keyid)
              );
              sendNotification(
                rhsMatcher.get("device_tokens"),
                rhsMatcher.get("language") === "he"
                  ? "תורך לשתף את ההצעה!"
                  : "Your turn to share the offer!",
                rhsMatcher.get("language") === "he"
                  ? " הי, " +
                      lhs_single_name +
                      " רוצה לצאת לדייט עם " +
                      rhs_single_name +
                      " - שתף את ההצעה עכשיו"
                  : "Hi," +
                      lhs_single_name +
                      " wants to go on a date with " +
                      rhs_single_name +
                      " - share the offer now",
                "SingleAccept"
              );

              setloader(false);
              setPleaseWait(true);
            }
          }

          if (lr_pid.charAt(0) === "r") {
            await updateDoc(
              doc(collection(firestore, "proposals"), lr_pid.substring(2)),
              {
                rhs_current_state: 2,
                updated_at: serverTimestamp2(),
              }
            );

            // this r side // real time data base for chat messages

            await push(
              ref(
                database,
                "conversations/" +
                  proposalSnap.get("conversation_id") +
                  "/messages/"
              ),
              {
                sender_id: rhs_keyid,
                content: JSON.stringify({
                  english: rhs_single_name + " is in for a date...",
                  hebrew: rhs_single_name + " רוצה דייט",
                }),
                message_type: 4,
                read_status: 0,
                timestamp: serverTimestamp(),
              }
            );

            setShow(true);
            setTimeout(() => {
              setShowDialog(true);
            }, 1500);

            if (lhsState === 2) {
              await updateDoc(doc(collection(firestore, "singles"), rcsid), {
                is_active: false,
                last_updated: serverTimestamp2(),
              });
              await updateDoc(doc(collection(firestore, "singles"), lcsid), {
                is_active: false,
                last_updated: serverTimestamp2(),
              });

              postapi();
            } else {
              const lhsMatcher = await getDoc(
                doc(firestore, "matchers", lhs_keyid)
              );
              sendNotification(
                lhsMatcher.get("device_tokens"),
                lhsMatcher.get("language") === "he"
                  ? "תורך לשתף את ההצעה!"
                  : "Your turn to share the offer!",
                lhsMatcher.get("language") === "he"
                  ? " הי, " +
                      rhs_single_name +
                      " רוצה לצאת לדייט עם " +
                      lhs_single_name +
                      " - שתף את ההצעה עכשיו"
                  : "Hi," +
                      rhs_single_name +
                      " wants to go on a date with " +
                      lhs_single_name +
                      " - share the offer now",
                "SingleAccept"
              );
              setloader(false);
              setPleaseWait(true);
            }
          }

          window.scrollTo(0, 0);
        }
    //   }
    // }
  };

  // post api call in firebase in side

  const postapi = async () => {
    // await updateDoc(
    //   doc(collection(firestore, "proposals"), lr_pid.substring(2)),
    //   {
    //     is_active: 0,
    //   }
    // );

    const lhsMatcher = await getDoc(doc(firestore, "matchers", lhs_keyid));

    if (lhsMatcher.exists()) {
      sendNotification(
        lhsMatcher.get("device_tokens"),
        lhsMatcher.get("language") === "he"
          ? "יש! סגרת מאצ׳!"
          : "It’s a match!",
        lhsMatcher.get("language") === "he"
          ? rhs_single_name + " ו- " + lhs_single_name + " בדרך לדייט"
          : rhs_single_name +
              " and " +
              lhs_single_name +
              " are going on a date",
        "Match"
      );
    }

    const rhsMatcher = await getDoc(doc(firestore, "matchers", rhs_keyid));

    if (rhsMatcher.exists()) {
      sendNotification(
        rhsMatcher.get("device_tokens"),
        rhsMatcher.get("language") === "he"
          ? "יש! סגרת מאצ׳!"
          : "It’s a match!",
        rhsMatcher.get("language") === "he"
          ? rhs_single_name + " ו- " + lhs_single_name + " בדרך לדייט"
          : rhs_single_name +
              " and " +
              lhs_single_name +
              " are going on a date",
        "Match"
      );
    }

    setloader(false);

    Navigate(`/match-success?lrpid=${lr_pid}`);
  };

  const sendNotification = (deviceToken, title, body, type) => {
    deviceToken.forEach(async (dToken) => {
      await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: "key=" + serverKey,
        },
        body: JSON.stringify({
          to: dToken["token"],
          data: {
            type: type,
            record_id: lr_pid.substring(2),
          },
          notification: {
            title: title,
            body: body,
          },
        }),
      });
    });
  };

  return (
    <>
      <Modal show={loader} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="main-home" id="top">
        <div className="container  first-container">
          <div className="row universal-row-box">
            <div className="col-12 main-top-logo-time-box">
              <div className="logo-div main-home-logo-bg">
                <img
                  className="universal-logo main-web-home-logo"
                  src={whitelogo}
                  alt="logo"
                />
              </div>
              <div className="main-home-logo-col">
                {/* <div className="row time-set-clock-row">
                  <div className="col-12 time-set-clock-col">
                    <h6 id="timer">{time}</h6>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-12 ">
              <div className="container-fluid time-set-clock-container">
                {show ? (
                  <div className="row time-set-title-row">
                    <div className="col-12 time-set-title-col">
                      <div>
                        <img
                          src={pluse}
                          alt=""
                          className="left-plus-home-web-icons"
                          style={{ width: "8px", height: "8px" }}
                        />
                      </div>
                      <h4>
                        <span>
                          <i
                            style={{
                              color: "#09E47B",
                              fontSize: "20px",
                              paddingLeft: "8px",
                            }}
                            className="bi bi-check-circle-fill"
                          ></i>
                        </span>
                        <b>{t("title-web2")}</b>
                      </h4>
                      <div>
                        <img
                          src={pluse}
                          alt=""
                          className="right-plus-home-web-icons"
                          style={{ width: "8px", height: "8px" }}
                        />
                      </div>
                      <p className="para-user-profile">
                        {t("para-web").replace("<xxxxx>", name)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row time-set-title-row">
                    <div className="col-12 time-set-title-col">
                      <h2>{t("title-web").replace("<xxxxx>", name)}</h2>
                    </div>
                  </div>
                )}
              </div>
              <div className="container-fluid main-home-image-slider-container">
                <div className="row main-home-image-slider-row">
                  <div className="col-12 main-home-image-slider-col">
                    {/* sliders code start */}
                    <div
                      id="carouselExampleCaptions"
                      className="carousel slide"
                      data-bs-ride="false"
                    >
                      <div className="carousel-indicators">
                        <div className="container-fluid main-home-tags-selected-data-container">
                          <div
                            ref={refCarouselIndicators}
                            className="row main-home-button-indicator-row"
                          >
                            {imageUrls.length > 1 &&
                              imageUrls.map((item, index) => (
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleCaptions"
                                  data-bs-slide-to={index}
                                  className={index === 0 ? "active" : ""}
                                  aria-current={index === 0}
                                  aria-label={`Slide ${index}`}
                                ></button>
                              ))}
                          </div>
                          <div
                            className="row main-home-tags-selected-data-row"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {lookingPersonality.map((item) => (
                              <>
                                <div className="col-4 main-home-tags-selected-data-col">
                                  <p>{item.get("hebrew")}</p>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-caption main-home-text-position-seting">
                          <h5
                            style={{ textShadow: "2px 2px 2px rgba(0,0,0,1)" }}
                          >
                            {name}
                          </h5>
                          <p
                            style={{
                              textShadow: "2px 1.2px 1.9px rgba(0,0,0,1)",
                            }}
                          >
                            {age + ", " + location}
                          </p>
                          <p
                            style={{
                              textShadow: "2px 1.2px 1.9px rgba(0,0,0,1)",
                            }}
                          >
                            {occupation}
                          </p>
                          <p
                            style={{
                              textShadow: "2px 1.2px 1.9px rgba(0,0,0,1)",
                            }}
                          >
                            {lifeStyle}
                          </p>
                        </div>
                        {/* <div className="brightness-box">
                          <input type="range" onChange={ sliderChange } id="range" min="10" max="100" value="100" />
                        </div> */}
                        {imageUrls.map((item, index) => (
                          <div
                            id={"main-web-home-carousel-iteam" + index}
                            className={
                              index === 0
                                ? "carousel-item active"
                                : "carousel-item"
                            }
                          >
                            <img
                              src={item.url}
                              className="d-block w-100 main-home-profile-img-slide"
                              alt="img-1"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {extraInfoRecorded !== null && extraInfoRecorded !== "" ? (
                  <div className="main-home-video-container">
                    <div className="row main-home-video-row">
                      <div className="col-8 main-home-video-col">
                        <h6>{t("play-button")}</h6>
                        <p>{audioDuration}</p>
                      </div>
                      <div className="col-4 main-home-video-col-bottum">
                        <button onClick={playPause}>
                          <i className={playButtonClass}></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : extraInfo != null && extraInfo != "" ? (
                  <div className="main-home-text-container">
                    <p>
                      <span>{"חברים מספרים"}</span>
                      <br />
                      {extraInfo}
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="container-fluid main-home-video-container">
                <div className="row main-home-button-row">
                  {!show && (
                    <div className="col-12 main-home-button-col">
                      <div className="main-home-button-box">
                        <button
                          data-bs-toggle="modal"
                          type="button"
                          data-bs-target="#exampleModal3"
                          className="btn btn-primary main-home-btn-deselect-profile"
                          aria-label="Close"
                        >
                          <img
                            src={closeicon}
                            alt="Close"
                            className="close-btn"
                          />
                        </button>
                        {t("exit-btn")}
                      </div>

                      <div className="main-home-button-box">
                        <button
                          className="btn btn-primary"
                          type="button"
                          aria-label="Close"
                          onClick={acceptDate}
                        >
                          <i className="bi bi-heart-fill"></i>
                        </button>
                        {t("like-btn")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeartPopup csid={csid} matcherData={matcherData} />
      {/* please wait for request accept */}
      {/* {PleaseWait && (
        <div className="popup-3" onClick={() => setPleaseWait(false)}>
          <div className="popup-good popup-existing-profile "></div>
          <div className="modal d-block splash-auto-open-popup">
            <div className="modal-dialog splash-modal">
              <div className="modal-content splash-modal-content">
                <div className="modal-body">
                  <h2 className="popup-title-top">
                    You are Accepted please wait not other side Accepted
                  </h2>
                  <div className="terms_popup"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {/* become matcher popup */}
      {showDialog && (
        <>
          <div className="popup-good popup-existing-profile "></div>
          <div className="modal d-block splash-auto-open-popup">
            <div className="modal-dialog splash-modal-dialog">
              <div className="modal-content splash-modal-content-2 modal-content-change">
                <div className="modal-body">
                  <div className="main-img-heartpopup main-img-existing-popup popup-change">
                    <img
                      src={popupBecomeMatcherImg}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                    />
                  </div>
                  <h2 className="popup-title-top main-existing-popup-title">
                    <span>{t("title_become_matcher_1")}</span>
                    <br />
                    <span>{t("title_become_matcher_2")}</span>
                  </h2>

                  <div class="google-figure">
                    <img
                      src={googleplay}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                      onClick={() => {
                        window.location.href =
                          "https://play.google.com/store/apps/details?id=com.matchersapp.app";
                      }}
                    />
                  </div>

                  <div class="google-figure">
                    <img
                      src={appstore}
                      className="d-block w-100 main-home-profile-img-slide"
                      alt="img-1"
                      onClick={() => {
                        window.location.href =
                          "https://apps.apple.com/app/id1640479105";
                      }}
                    />
                  </div>
                  <div class="change-btn">
                    <button
                      onClick={() => setShowDialog(false)}
                      className="button-accept button-blank-box button_exit"
                      style={{ backgroundColor: "transparent", color: "white" }}
                    >
                      {t("btn_become_matcher")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
