import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";

import Splash from "./Component/MainComponent/Splash";
import SelectImage from "./Component/MainComponent/SelectImage";

import SelectDetails from "./Component/MainComponent/SelectDetails";

import CropFile from "./Component/MainComponent/CropFile";
import UploadUserGallery from "./Component/MainComponent/UploadUserGallery";
import UserGender from "./Component/MainComponent/UserGender";
import SelectOption from "./Component/MainComponent/Selectoption";
import PageNotFound from "./Component/MainComponent/PageNotFound";

import SelectHeight from "./Component/MainComponent/SlectHeight";
import SelectAge from "./Component/MainComponent/SelectAge";
import SelectAgeRange from "./Component/MainComponent/SelectAgeRange";
import SelectLifeStyleLooking from "./Component/MainComponent/SelectLifeStyleLooking";
import SelectLocation from "./Component/MainComponent/SelectLocation";
import SelectOccupation from "./Component/MainComponent/SelectOccupation";
import SelectLifestyle from "./Component/MainComponent/SelectLifestyle";
import SelectPolitics from "./Component/MainComponent/SelectPolitics";
import SelectGeography from "./Component/MainComponent/SelectGeography";
import Decline from "./Component/MainComponent/ProposalWeb/Decline";
import ProposalIrrelevant from "./Component/MainComponent/ProposalWeb/Proposalirrelevant";
import MatchSuccess from "./Component/MainComponent/ProposalWeb/MatchSuccess";

import { isMobile } from "react-device-detect";

import app, { appAuthentication } from "./Firebase/Firebase";

import Authentication from "./Authentication/Authentication";
import PageLoder from "./Authentication/PageLoder";
import { useDispatch } from "react-redux";

import SingleProfiles from "./Component/MainComponent/ProposalWeb/SingleProfile";
import ShareLink from "./ShareLink/Sharelink";
import Signin from "./Component/MainComponent/ProposalWeb/Signin";
import Smsotp from "./Component/MainComponent/ProposalWeb/Smsotp";
import PersonalArea from "./Component/MainComponent/ProposalWeb/PersonalArea";
import PersonalSplash from "./Component/MainComponent/ProposalWeb/PersonalSplash";
import ProfileDeleteSplash from "./Component/MainComponent/ProposalWeb/ProfileDeleteSplash";

import { onAuthStateChanged } from "firebase/auth";
import TermsAndConditions from "./Component/TermsAndConditions"

import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";

import {
  ASSOCIATED_PROFILES,
  LHS_KEY,
  MATCHER_ID,
  MATCH_REJECT,
  MOBLIE_NUMBER,
  PERSONANLITY_DATA,
  REJECTRESON_DATA,
  RHS_KEY,
  SINGLES_ID,
  SINGLE_DOCID,
  SINGLE_GEN,
  SINGLE_LOOKING_GEN,
} from "./Redux/constants";

var sdid = "";

function App() {
  const [value, setValue] = useState(false);
  const [valueCheck, setValueCheck] = useState(false);
  const [alreadyLogin, setAlreadyLogin] = useState(false);
  const [pending, setPending] = useState(true);
  const [lhsRhs, setLhsRhs] = useState(false);

  const dispatch = useDispatch();
  const id = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");
  const lr_pid = new URLSearchParams(window.location.search).get("lrpid");

  const firestore = getFirestore(app);

  useEffect(() => {
    var root = document.getElementById("root");
    root.style.margin = "0 auto";
    root.style.height = "100%";

    isMobile ? (root.style.maxWidth = "100%") : (root.style.maxWidth = "375px");

    window.addEventListener(
      "popstate",
      (event) => {
        if (
          event.target.location.pathname === "/looking-personality-traits" ||
          event.target.location.pathname === "/che ck-details"
        ) {
          if (id != null && id != "") {
            window.location.href = event.target.origin + `/?id=${id}`;
          } else if (sid != null && sid != "") {
            window.location.href = event.target.origin;
          }
        }
      },
      false
    );
  }, []);

  onAuthStateChanged(appAuthentication, async (user) => {
    if (user) {
      const lookingPersonalityTraits = await getDocs(
        query(collection(firestore, "personality_traits"))
      );

      // send lookingPersonalityTraits with dispatch

      dispatch({
        type: PERSONANLITY_DATA,
        payload: lookingPersonalityTraits.docs,
      });

      if (sid != null && sid != "") {
        const docSnapSingles = await getDoc(doc(firestore, "singles", sid));
        if (docSnapSingles.exists) {
          dispatch({
            type: SINGLE_LOOKING_GEN,
            payload: docSnapSingles.get("looking_for_gender"),
          });
        }
      }

      if (id !== null && id !== "") {
        doesDocExist(user.phoneNumber);
      } else if (lr_pid !== null && lr_pid !== "") {
        const rejectReasonData = await getDocs(
          query(collection(firestore, "single_reject_reason"))
        );

        dispatch({
          type: REJECTRESON_DATA,
          payload: rejectReasonData.docs,
        });

        checkUser(lr_pid.charAt(0), lr_pid.substring(2), user.phoneNumber);
      } else {
        setAlreadyLogin(true);
        setPending(false);
      }
    } else {
      setPending(false);
    }
  });

  const doesDocExist = async (phoneNumber) => {
    try {
      const docSnap = await getDoc(doc(firestore, "single_profiles", id));

      if (docSnap.exists()) {
        console.log("gender", docSnap.get("gender"));

        dispatch({
          type: SINGLE_GEN,
          payload: docSnap.get("gender"),
        });

        if (docSnap.get("phone_number").replace("-", "") == phoneNumber) {
          // send MOBLIE_NUMBER with dispatch

          dispatch({
            type: MOBLIE_NUMBER,
            payload: docSnap.get("phone_number"),
          });

          // send single_id from single_profiles with dispatch

          sdid = docSnap.get("single_id");

          dispatch({
            type: SINGLE_DOCID,
            payload: docSnap.get("single_id"),
          });

          // send matcher_id from single_profiles with dispatch

          dispatch({ type: MATCHER_ID, payload: docSnap.get("matcher_id") });

          const querySnapshot = await getDocs(
            query(
              collection(firestore, "singles"),
              where("phone_number", "==", docSnap.get("phone_number"))
            )
          );

          // send document id from singles collection with dispatch

          dispatch({
            type: SINGLES_ID,
            payload:
              querySnapshot.docs.length > 0 ? querySnapshot.docs[0].id : "",
          });

          // send associated_profile in array from singles collection with dispatch

          dispatch({
            type: ASSOCIATED_PROFILES,
            payload:
              querySnapshot.docs.length > 0
                ? querySnapshot.docs[0].get("associated_profiles") != null
                  ? querySnapshot.docs[0].get("associated_profiles")
                  : []
                : [],
          });

          setValue(true);
        } else {
          setValue(false);
        }
      }
      setAlreadyLogin(true);
      setPending(false);
    } catch (e) {
      if (
        e.message.includes("Missing or insufficient permissions") &&
        !window.location.href.includes("shareit") &&
        !window.location.href.includes("checkredirectlink")
      ) {
        alert("Error: " + e.message);
      }

      // unsubscribe();
      // appAuthentication.signOut().then(
      //   function () {
      //     window.location.href = window.location.href;
      //   },
      //   function (error) {
      //     console.log("error -- ", error.message);
      //     return false;
      //   }
      // );
    }
  };

  // get single id fo singles page
  const checkUser = async (lr, pid, phoneNumber) => {
    try {
      const proposalSnap = await getDoc(doc(firestore, "proposals", pid));

      if (proposalSnap.exists()) {
        var check = false;

        if (
          lr == "l" &&
          proposalSnap.get("lhs_single_phone_number").replace("-", "") ==
            phoneNumber
        ) {
          check = true;
        } else if (
          lr == "r" &&
          proposalSnap.get("rhs_single_phone_number").replace("-", "") ==
            phoneNumber
        ) {
          check = true;
        }

        if (check) {
          setLhsRhs(
            proposalSnap.get("lhs_current_state") === 2 &&
              proposalSnap.get("rhs_current_state") === 2
          );

          // forword lhs and rhs key with dispatch function

          dispatch({
            type: LHS_KEY,
            payload: proposalSnap.get("lhs_matcher"),
          });
          dispatch({
            type: RHS_KEY,
            payload: proposalSnap.get("rhs_matcher"),
          });

          dispatch({
            type: MATCH_REJECT,
            payload: proposalSnap.get("match_reject_reason") >= 0,
          });

          setValueCheck(true);
        } else {
          setValueCheck(false);
        }
      }

      setAlreadyLogin(true);
      setPending(false);
    } catch (e) {
      appAuthentication.signOut().then(
        function () {
          setPending(false);
        },
        function (error) {
          console.log("error -- ", error.message);
          return false;
        }
      );
    }
  };

  // All routes this side

  return pending ? (
    <PageLoder />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/checkredirectlink/:id" element={<ShareLink />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/profileDeleteSplash" element={<ProfileDeleteSplash />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
      {id != null && id != "" ? (
        sdid != null && sdid != "" ? (
          <Routes>
            <Route path="/" element={value ? <Splash /> : <Authentication />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/signin" element={<Signin id={id} lrpid={lr_pid} />} />
            <Route path="/verify-otp" element={<Smsotp />} />
            <Route
              path="/user-details-select"
              element={<UploadUserGallery />}
            />
            <Route
              path="/check-details"
              element={value ? <SelectDetails /> : <Authentication />}
            />
            <Route
              path="/crop-file"
              element={value ? <CropFile /> : <Authentication />}
            />
            <Route
              path="/select-image"
              element={value ? <SelectImage /> : <Authentication />}
            />
            <Route
              path="/select-height"
              element={value ? <SelectHeight /> : <Authentication />}
            />

            <Route
              path="/select-age"
              element={value ? <SelectAge /> : <Authentication />}
            />
            <Route
              path="/select-location"
              element={value ? <SelectLocation /> : <Authentication />}
            />
            <Route
              path="/select-occupation"
              element={value ? <SelectOccupation /> : <Authentication />}
            />
            <Route
              path="/select-lifestyle"
              element={value ? <SelectLifestyle /> : <Authentication />}
            />
            <Route
              path="/select-politics"
              element={value ? <SelectPolitics /> : <Authentication />}
            />

            <Route
              path="/looking-for-gender"
              element={value ? <UserGender /> : <Authentication />}
            />

            <Route
              path="/select-agerange"
              element={value ? <SelectAgeRange /> : <Authentication />}
            />
            <Route
              path="/select-lifestyle-looking"
              element={value ? <SelectLifeStyleLooking /> : <Authentication />}
            />
            <Route
              path="/select-geography"
              element={value ? <SelectGeography /> : <Authentication />}
            />

            <Route
              path="/looking-personality-traits"
              element={value ? <SelectOption /> : <Authentication />}
            />
            <Route
              path="/splash"
              element={value ? <Splash /> : <Authentication />}
            />
            <Route
              path="*"
              element={
                window.location.href.search("shareit") === -1 && (
                  <PageNotFound />
                )
              }
            />
          </Routes>
        )
      ) : lr_pid != null && lr_pid != "" ? (
        <Routes>
          <Route
            path="/"
            element={
              alreadyLogin ? (
                valueCheck ? (
                  lhsRhs ? (
                    <MatchSuccess />
                  ) : (
                    <SingleProfiles />
                  )
                ) : (
                  <PersonalSplash />
                )
              ) : (
                <Signin id={id} lrpid={lr_pid} />
              )
            }
          />
          <Route
            path="/verify-otp"
            element={
              alreadyLogin ? (
                valueCheck ? (
                  lhsRhs ? (
                    <MatchSuccess />
                  ) : (
                    <SingleProfiles />
                  )
                ) : (
                  <PersonalSplash />
                )
              ) : (
                <Smsotp />
              )
            }
          />

          <Route
            path="/decline"
            element={valueCheck ? <Decline /> : <Authentication />}
          />

          <Route
            path="/match-success"
            element={valueCheck ? <MatchSuccess /> : <Authentication />}
          />

          <Route
            path="/proposalIrrelevant"
            element={valueCheck ? <ProposalIrrelevant /> : <Authentication />}
          />
        </Routes>
      ) : sid != null && sid != "" && alreadyLogin ? (
        <Routes>
          <Route path="/select-image" element={<SelectImage />} />
          <Route path="/user-details-select" element={<UploadUserGallery />} />
          <Route path="/check-details" element={<SelectDetails />} />
          <Route path="/crop-file" element={<CropFile />} />
          <Route path="/select-height" element={<SelectHeight />} />
          <Route path="/select-age" element={<SelectAge />} />
          <Route path="/select-location" element={<SelectLocation />} />
          <Route path="/select-occupation" element={<SelectOccupation />} />
          <Route path="/select-lifestyle" element={<SelectLifestyle />} />
          <Route path="/select-politics" element={<SelectPolitics />} />
          <Route path="/looking-for-gender" element={<UserGender />} />
          <Route path="/select-agerange" element={<SelectAgeRange />} />
          <Route
            path="/select-lifestyle-looking"
            element={<SelectLifeStyleLooking />}
          />
          <Route path="/select-geography" element={<SelectGeography />} />

          <Route
            path="/looking-personality-traits"
            element={<SelectOption />}
          />
          <Route path="/splash" element={<Splash />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/"
            element={alreadyLogin ? <PersonalArea /> : <Signin />}
          />
          <Route
            path="/verify-otp"
            element={alreadyLogin ? <PersonalArea /> : <Smsotp />}
          />
          <Route path="/personalsplash" element={<PersonalSplash />} />
        </Routes>
      )}
      {/* <Route path="/terms" element={<TermsAndConditions />}/> */}
    </BrowserRouter>
  );
}

export default App;