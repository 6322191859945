import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { POLITICS_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

import politics_male_1 from "../../Common/Assets/background/politics_male_1.png";
import politics_male_2 from "../../Common/Assets/background/politics_male_2.png";
import politics_male_3 from "../../Common/Assets/background/politics_male_3.png";
import politics_male_4 from "../../Common/Assets/background/politics_male_4.png";
import politics_male_5 from "../../Common/Assets/background/politics_male_5.png";
import politics_female_1 from "../../Common/Assets/background/politics_female_1.png";
import politics_female_2 from "../../Common/Assets/background/politics_female_2.png";
import politics_female_3 from "../../Common/Assets/background/politics_female_3.png";
import politics_female_4 from "../../Common/Assets/background/politics_female_4.png";
import politics_female_5 from "../../Common/Assets/background/politics_female_5.png";

const SelectPolitics = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [politics_options, setPoliticsOptions] = useState(1);

  const { single_gen, single_looking_gen } = useSelector((state) => state);

  var gender =
    sid != null && sid != "" ? (single_looking_gen == 0 ? 1 : 0) : single_gen;

  const [backgroundImage, setBackgroundImage] = useState(
    gender == 0 ? politics_male_2 : politics_female_2
  );

  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const firestore = getFirestore(app);

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    if (sid != null && sid != "") {
      getData();
    }

    // document.body.style.setProperty("overflow", "hidden", "important");
    // document.body.style.overscrollBehaviorY = "contain";

    // return () => {
    //   document.body.style.setProperty("overflow", "visible", "important");
    //   document.body.style.overscrollBehaviorY = "none";
    // };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const political_factor = docSnap.get("political_factor");

      if (political_factor != null) {
        backgroungForPolitics(political_factor);
        setPoliticsOptions(political_factor);
      }
    }
    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    setShowPopup(false);

    console.log("politics_options", politics_options);

    dispatch({ type: POLITICS_DATA, payload: politics_options });

    if (sid != null && sid != "") {
      await updateDoc(doc(collection(firestore, "singles"), sid), {
        political_factor: politics_options,
        last_updated: serverTimestamp(),
      });
    }

    if (linkId != null && linkId != "") {
      Navigate(`/looking-for-gender?id=${linkId}`);
    } else {
      Navigate(`/looking-for-gender?sid=${sid}`);
    }
  };

  const backgroungForPolitics = (politics) => {
    console.log("gender :- ", gender);

    if (gender == 0) {
      if (politics == 1) {
        setBackgroundImage(politics_male_2);
      } else if (politics == 2) {
        setBackgroundImage(politics_male_5);
      } else if (politics == 3) {
        setBackgroundImage(politics_male_1);
      } else if (politics == 4) {
        setBackgroundImage(politics_male_4);
      } else if (politics == 5) {
        setBackgroundImage(politics_male_3);
      }
    } else if (gender == 1) {
      if (politics == 1) {
        setBackgroundImage(politics_female_2);
      } else if (politics == 2) {
        setBackgroundImage(politics_female_5);
      } else if (politics == 3) {
        setBackgroundImage(politics_female_1);
      } else if (politics == 4) {
        setBackgroundImage(politics_female_4);
      } else if (politics == 5) {
        setBackgroundImage(politics_female_3);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home location polticias"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine location"
                  src={Logo}
                  alt=""
                />
              </div>

              {/* <div class="border-line">
                <div></div>
                <div class="second"></div>
                <div class="third"></div>
                <div class="forth"></div>
              </div> */}

              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text location politics">
                  <b>{"עמדה פוליטית/חברתית"}</b>
                </p>
              </div>

              <div class="politicas">
                <div class="range">
                  <input
                    type="range"
                    max={5}
                    min={1}
                    class="form-range"
                    id="customRange1"
                    value={politics_options}
                    onChange={(e) => {
                      setPoliticsOptions(parseInt(e.target.value));
                      backgroungForPolitics(parseInt(e.target.value));
                    }}
                  />
                </div>
                <div class="policas-content">
                  <p style={politics_options < 3 ? { fontWeight: "bold" } : {}}>
                    {"הכי שמאל שיש"}
                  </p>
                  <p
                    style={politics_options == 3 ? { fontWeight: "bold" } : {}}
                  >
                    {"מקום טוב באמצע"}
                  </p>
                  <p style={politics_options > 3 ? { fontWeight: "bold" } : {}}>
                    {"הכי ימין שיש"}
                  </p>
                </div>
              </div>

              <section class="location">
                <div class="container">
                  <div location-wrap></div>
                </div>
              </section>

              <div className="image-group1 location-btn">
                <div className="button-div btn-change">
                  <button
                    className="button-accept profile-button"
                    onClick={() => setShowPopup(true)}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>

      {/*======= new-modal =======*/}
      {showPopup && (
        <>
          <div className="popup-2">
            <div className="popup-good popup-existing-profile "></div>
            <div
              className="modal d-block splash-auto-open-popup"
              onClick={() => setShowPopup(false)}
            >
              <div className="modal-dialog success-modal">
                <div className="modal-content success-modal-content change">
                  <div className="modal-body success-modal-body change">
                    <div class="cross">
                      <i class="bi bi-x"></i>
                    </div>

                    <h2 class="hello"> נעים להכיר 👋</h2>

                    <p class="new-text-content">
                      עכשיו כמה שאלות שיעזרו לנו לדעת מה לחפש לך
                    </p>

                    <button className="button-exit-success " onClick={saveData}>
                      {"בואו נמשיך"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default SelectPolitics;
