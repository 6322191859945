import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import select_location from "../../Common/Assets/Images/select_location.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LOCATION_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

import location_male_1 from "../../Common/Assets/background/location_male_1.png";
import location_male_2 from "../../Common/Assets/background/location_male_2.png";
import location_male_3 from "../../Common/Assets/background/location_male_3.png";
import location_male_4 from "../../Common/Assets/background/location_male_4.png";
import location_male_5 from "../../Common/Assets/background/location_male_5.png";
import location_female_1 from "../../Common/Assets/background/location_female_1.png";
import location_female_2 from "../../Common/Assets/background/location_female_2.png";
import location_female_3 from "../../Common/Assets/background/location_female_3.png";
import location_female_4 from "../../Common/Assets/background/location_female_4.png";
import location_female_5 from "../../Common/Assets/background/location_female_5.png";

const SelectLocation = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [location_option, setLocationOption] = useState("-1");
  const [show, setShow] = useState(false);
  const firestore = getFirestore(app);

  const locationData = [
    { english: "Tel-Aviv/Center", hebrew: "תל-אביב/מרכז" },
    { english: "Jerusalem and surroundings", hebrew: "ירושלים והסביבה" },
    { english: "North", hebrew: "צפון" },
    { english: "South", hebrew: "דרום" },
  ];

  const { single_gen, single_looking_gen } = useSelector((state) => state);

  var gender =
    sid != null && sid != "" ? (single_looking_gen == 0 ? 1 : 0) : single_gen;

  const [backgroundImage, setBackgroundImage] = useState(
    gender == 0 ? location_male_1 : location_female_1
  );

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("", "", "");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("", "", "");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const location = docSnap.get("location");

      if (location != null) {
        backgroungForLocation(location);

        setLocationOption(location.toString());
      }
    }
    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    console.log("location_option", location_option);

    if (location_option != "-1") {
      dispatch({ type: LOCATION_DATA, payload: parseInt(location_option) });

      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          location: parseInt(location_option),
          last_updated: serverTimestamp(),
        });
      }

      if (linkId != null && linkId != "") {
        Navigate(`/select-occupation?id=${linkId}`);
      } else {
        Navigate(`/select-occupation?sid=${sid}`);
      }
    }
  };

  const backgroungForLocation = (location) => {
    if (gender == 0) {
      if (location == 0) {
        setBackgroundImage(location_male_2);
      } else if (location == 1) {
        setBackgroundImage(location_male_3);
      } else if (location == 2) {
        setBackgroundImage(location_male_4);
      } else if (location == 3) {
        setBackgroundImage(location_male_5);
      } else {
        setBackgroundImage(location_male_1);
      }
    } else if (gender == 1) {
      if (location == 0) {
        setBackgroundImage(location_female_2);
      } else if (location == 1) {
        setBackgroundImage(location_female_3);
      } else if (location == 2) {
        setBackgroundImage(location_female_4);
      } else if (location == 3) {
        setBackgroundImage(location_female_5);
      } else {
        setBackgroundImage(location_female_1);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home location"
        style={{
          height: window.innerHeight,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine location"
                  src={Logo}
                  alt=""
                />
              </div>

              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text location">
                  <b>{"אזור מגורים"}</b>
                </p>
              </div>

              <div class="select-content">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={location_option}
                  onChange={(e) => {
                    setLocationOption(e.target.value);
                    backgroungForLocation(parseInt(e.target.value));
                  }}
                >
                  <option value="-1"></option>
                  <option value="0">{"תל-אביב/מרכז"}</option>
                  <option value="1">{"ירושלים והסביבה"}</option>
                  <option value="2">{"צפון"}</option>
                  <option value="3">{"דרום"}</option>
                </select>
              </div>

              <section class="location">
                <div class="container">
                  <div location-wrap></div>
                </div>
              </section>

              <div className="image-group1 location-btn">
                <div className="button-div btn-change">
                  <button
                    className="button-accept profile-button"
                    style={
                      location_option != -1
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "grey" }
                    }
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectLocation;
