import React, { useState, useEffect, useRef } from "react";
import Logo from "../../Common/Assets/Images/logo.png";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { IMAGE_DATA, SELECT_IMAGE, SELECT_INDEX } from "../../Redux/constants";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "@firebase/storage";
import app from "../../Firebase/Firebase";

import { useTranslation } from "react-i18next";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { Modal } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

const dataURLtoFile = (dataurl, filename) => {
  // Chnage basr64 data in image file with this function

  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export default function SelectImage() {
  const [isLoading, setLoading] = useState(false);
  const { image } = useSelector((state) => state);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storage = getStorage(app);
  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [profileImg, setProfileImage] = useState(image);

  const firestore = getFirestore(app);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (sid != null && sid != "") {
      getData();
    }
  }, []);

  const getData = async () => {
    setLoading(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));
    const profilePictures = docSnap.get("profile_pictures");

    var data = [...profileImg];

    if (profilePictures[0] != null && data[0].url == "") {
      data[0].url = profilePictures[0].url;
      data[0].height = profilePictures[0].height;
      data[0].width = profilePictures[0].width;
    }

    if (profilePictures[1] != null && data[1].url == "") {
      data[1].url = profilePictures[1].url;
      data[1].height = profilePictures[1].height;
      data[1].width = profilePictures[1].width;
    }

    if (profilePictures[2] != null && data[2].url == "") {
      data[2].url = profilePictures[2].url;
      data[2].height = profilePictures[2].height;
      data[2].width = profilePictures[2].width;
    }

    console.log("imgData", data);

    setProfileImage(data);

    setLoading(false);
  };


  const getImageLiveUrl = async (elementUrl, index) => {
    var url = "";
    if (elementUrl != "") {
      if (elementUrl.includes("https://firebasestorage.googleapis.com/")) {
        url = elementUrl;
      } else {
        const fileName =
          (linkId == null || linkId == "" ? "s_" + sid : "sp_" + linkId) +
          "_" +
          index +
          "_" +
          new Date().getTime() +
          ".jpg";

        const storageRef = ref(storage, "profile_pictures/" + fileName);

        var file = dataURLtoFile(elementUrl, fileName);

        var snapshot = await uploadBytes(storageRef, file);

        url = await getDownloadURL(snapshot.ref);
      }
    }

    return url;
  };


  // FireBase store image

  const FirebaseSendImage = async () => {
    setLoading(true);

    // this condition is check

    console.log(profileImg);

    var element1 = profileImg[0];
    var element2 = profileImg[1];
    var element3 = profileImg[2];

    var url1 = await getImageLiveUrl(element1.url, 0);
    var url2 = await getImageLiveUrl(element2.url, 1);
    var url3 = await getImageLiveUrl(element3.url, 2);

    const fileUrls = [
      { url: url1, height: element1.height, width: element1.width },
      { url: url2, height: element2.height, width: element2.width },
      { url: url3, height: element3.height, width: element3.width },
    ];

    // const fileUploads = profileImg.map(async (element, index) => {
    //   var url = "";
    //   if (element.url !== "") {
    //     if (element.url.includes("http")) {
    //       url = element.url;
    //     } else {
    //       const fileName =
    //         (linkId == null || linkId == "" ? "s_" + sid : "sp_" + linkId) +
    //         "_" +
    //         index +
    //         "_" +
    //         new Date().getTime() +
    //         ".jpg";

    //       const storageRef = ref(storage, "profile_pictures/" + fileName);

    //       var file = dataURLtoFile(element.url, fileName);

    //       var snapshot = await uploadBytes(storageRef, file);

    //       url = await getDownloadURL(snapshot.ref);
    //     }
    //   }

    //   return { url: url, height: element.height, width: element.width };
    // });

    // const fileUrls = await Promise.all(fileUploads);

    console.log("fileUrls", fileUrls);

    // dispatch image in looking-personality page

    dispatch({ type: IMAGE_DATA, payload: fileUrls });

    if (sid != null && sid != "") {
      var imgData = [];
      fileUrls.forEach((option, index) => {
        if (option.url !== "") {
          imgData.push({
            url: option.url,
            order: index,
            height: option.height,
            width: option.width,
          });
        }
      });

      await updateDoc(doc(collection(firestore, "singles"), sid), {
        profile_pictures: imgData,
        last_updated: serverTimestamp(),
      });
    }

    setLoading(false);

    if (linkId != null && linkId != "") {
      Navigate(`/select-height?id=${linkId}`);
    } else {
      Navigate(`/select-height?sid=${sid}`);
    }
  };

  const fileRef = useRef();

  const handleChange = async (e) => {
    setLoading(true);

    var file = e.target.files[0];

    if (file) {
      if (file.type.toString().includes("heic")) {
        file = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 1,
        });
      }

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        dispatch({
          type: SELECT_IMAGE,
          payload: e.target.result,
        });

        if (linkId != null && linkId != "") {
          Navigate(`/crop-file?id=${linkId}`);
        } else {
          Navigate(`/crop-file?sid=${sid}`);
        }
      };

      reader.readAsDataURL(compressedFile);
    }

    setLoading(false);
  };

  return (
    <>
      {/* loading modle */}
      <Modal show={isLoading} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div className="main-home">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container">
          <div className="row uploadImage_gallery universal-row-box">
            <div className="col-12 matchup-col">
              <div className="logo-div">
                <img className="universal-logo" src={Logo} alt="" />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>

                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div>
                <p className="paragraph-text select-page-pehragraph-text">
                  {t("select-image-title")}
                </p>
              </div>

              <div className="container-fluid">
                <div className="row upload-row-image">
                  {profileImg.map((item, index) => (
                    <div key={index} className="outside-div-for-icons">
                      <div
                        className="col-4 image-upload-4 "
                        style={
                          item.url
                            ? { border: "none" }
                            : { border: "2px solid rgba(238, 44, 70, 0.15)" }
                        }
                      >
                        <img
                          className="img-fluid"
                          alt=""
                          src={item.url ? item.url : null}
                          style={{
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          fileRef.current.click();
                          dispatch({ type: SELECT_INDEX, payload: index });
                        }}
                        className="round-button-select-data"
                      >
                        {item.url ? (
                          <i className="bi bi-pencil-fill"></i>
                        ) : (
                          <i className="bi bi-plus-lg"></i>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row select-image-gallery-box-text">
                  <ul className="list-user-gallery">
                    <li> {t("select-image-details1")} </li>
                    <li>{t("select-image-details2")}</li>
                    <li> {t("select-image-details3")}</li>
                    <li>{t("select-image-details4")} </li>
                  </ul>
                </div>
              </div>
              <div>
                <button
                  className="profile-button button-accept"
                  onClick={() => {
                    FirebaseSendImage();
                  }}
                >
                  {t("select-image-button")}
                </button>
                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
}
