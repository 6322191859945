import React, { useCallback, useEffect, useState } from "react";
import pluse from "../../Common/Assets/Images/plus.png";

import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CROP_IMAGE, SET_IMAGE } from "../../Redux/constants";

import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import getCroppedImg from "../../Cropping/CropImageProfile";
import { Modal } from "react-bootstrap";

function CropFile() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");
  const { image, selectIndex, imageSlect } = useSelector((state) => state);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // crop image file with this function

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSlect,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);

      var data = [...image];

      data[selectIndex].url = croppedImage;
      data[selectIndex].height = croppedAreaPixels.height;
      data[selectIndex].width = croppedAreaPixels.width;

      dispatch({
        type: SET_IMAGE,
        payload: data,
      });

      setTimeout(() => {
        if (data[0].url !== null && data[0].url !== "") {
          if (linkId != null && linkId != "") {
            Navigate(`/select-image?id=${linkId}`);
          } else {
            Navigate(`/select-image?sid=${sid}`);
          }
        }
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  // use redux get image through index

  //if blank image then back to upload file (navigate)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!imageSlect) {
      setTimeout(() => {
        Navigate(`/user-details-select?id=${linkId}`);
      }, 700);
    }
  }, []);

  return (
    <>
      {/* loading modle */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="main-home crop-backround-image  ">
        <div className="container">
          <div className="row Gallery-main-box-container universal-row-box1">
            <div className="col-12  matchup-col gallery-peh">
              <div>
                <p className="profile-para">{t("crop-page-title")}</p>
              </div>
              <div>
                <p className="profile-para-2">{t("crop-page-des")}</p>
              </div>
              <div>
                <img
                  src={pluse}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "295px",
                    marginTop: "60px",
                  }}
                />
              </div>

              <div>
                <Cropper
                  className="crop-file-size"
                  style={{
                    border: "1px dashed black",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  image={imageSlect}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={4 / 4}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                ></Cropper>
              </div>

              <div>
                <img
                  src={pluse}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                    marginLeft: "295px",
                    marginBottom: "75px",
                  }}
                />
              </div>

              <div>
                <button
                  className="button-accept upload-file-button"
                  onClick={() => {
                    setShow(true);
                    showCroppedImage();
                    dispatch({ type: CROP_IMAGE, payload: croppedImage });
                  }}
                >
                  {t("crop-page-button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CropFile;
