import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import personalAreaImg from "../../../Common/Assets/Images/dummyProfile.png";
import downloadIconImg from "../../../Common/Assets/Images/download-icon.png";
import logoutImg from "../../../Common/Assets/Images/logout_2.png";
import mailImg from "../../../Common/Assets/Images/mail.png";
import { useNavigate } from "react-router-dom";
import app, { appAuthentication } from "../../../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { conatctWithCode } from "../../../Common/contact _with_code";
import PageLoder from "../../../Authentication/PageLoder";
import { SINGLE_LOOKING_GEN } from "../../../Redux/constants";
import { useDispatch } from "react-redux";

export default function PersonalArea() {
  const [isActive, setActive] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pending, setPending] = useState(true);

  const [profileImg, setProfileImg] = useState(personalAreaImg);

  const [singleId, setSingleId] = useState("");

  const firestore = getFirestore(app);

  const [isLoading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  var lookingForGender = 0;

  const handleClick = async (e) => {
    setActive(e.target.checked);

    await updateDoc(doc(collection(firestore, "singles"), singleId), {
      id: singleId,
      is_active: e.target.checked,
      last_updated: serverTimestamp(),
    });
  };

  const logout = () => {
    appAuthentication.signOut().then(
      function () {
        window.location.replace("/");
        console.log("user logging out");
      },
      function (error) {
        console.log("error -- ", error.message);
        return false;
      }
    );
  };

  const contactUs = async () => {
    const snap = await getDocs(collection(firestore, "contact_us_phone"));
    var phone = snap.docs[0].get("phone");

    window.location.href =
      "https://wa.me/" + phone.substring(1, phone.length).replaceAll("-", "");
  };

  const yesDeleteIt = async () => {
    setShow(false);

    setLoading(true);

    console.log(singleId);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", singleId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const res = await fetch(
      "https://matcher-prod-api.web.app/single_delete",
      requestOptions
    );

    console.log(await res.json());

    await appAuthentication.signOut();

    setLoading(false);

    window.location.replace("/profileDeleteSplash");
  };

  useEffect(() => {
    onAuthStateChanged(appAuthentication, async (user) => {
      if (user) {
        var phone = user.phoneNumber;

        const querySnapshot = await getDocs(
          query(
            collection(firestore, "singles"),
            where("phone_number", "==", conatctWithCode(phone)),
            orderBy("last_updated", "desc"),
            limit(1)
          )
        );

        if (querySnapshot.docs.length > 0) {
          var imgList = querySnapshot.docs[0].get("profile_pictures");
          if (
            imgList.length > 0 &&
            imgList[0] != null &&
            imgList[0].url != null
          ) {
            setProfileImg(imgList[0].url);
          } else {
            setProfileImg(personalAreaImg);
          }
          setSingleId(querySnapshot.docs[0].id);
          setActive(querySnapshot.docs[0].get("is_active"));

          lookingForGender = querySnapshot.docs[0].get("looking_for_gender");

          dispatch({
            type: SINGLE_LOOKING_GEN,
            payload: lookingForGender,
          });

          setPending(false);
        } else {
          navigate("/personalsplash");
        }
      } else {
        navigate("/");
      }
    });
  }, []);

  return pending ? (
    <PageLoder />
  ) : (
    <>
      {/* loading page */}
      <Modal show={isLoading} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="container-fluid home-universal-container-box">
        <div className="main-home signin-main-home-box">
          <div className="container ">
            <div className="row universal-row-box home-universal-row-box">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row home-header-box personal-area-home-header-box all-data-center-box">
                    <img
                      className="img-fluid universal-logo universal-logo-top-distance"
                      alt="logo"
                      src="./images/logo.png"
                    />
                    <div className="personal-logo-side-button">
                      <button onClick={logout}>
                        <img className="logoutimg" src={logoutImg} />
                      </button>
                      <button onClick={contactUs}>
                        <img className="mailimg" src={mailImg} />
                      </button>
                    </div>
                  </div>
                  <div className="row signin-screen-custum-design-row universal-center-box-data">
                    <h2 className="personal-area-top-heading-text-box">
                      הפרופיל שלך
                    </h2>
                    <div className="personal-area-screen-custum-design-img-box universal-center-box-data">
                      <img
                        className={`img-fluid home-vector-screen-1 ${
                          isActive
                            ? "home-vector-screen-1"
                            : "home-vector-screen-1-img-blur"
                        }`}
                        src={profileImg}
                        alt="homeMain"
                      />
                    </div>

                    <div className="personal-input-box universal-center-box-data">
                      <label
                        className={`personal-left-text-switch-box ${
                          isActive
                            ? "personal-left-text-switch-box"
                            : "personal-left-text-switch-box-text-color-dark"
                        }`}
                      >
                        תפוס/ה כרגע
                      </label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={handleClick}
                          checked={isActive}
                        />
                        <span className="slider round"></span>
                      </label>
                      <label
                        className={`personal-right-text-switch-box ${
                          isActive
                            ? "personal-right-text-switch-box"
                            : "personal-right-text-switch-box-text-color-light"
                        }`}
                      >
                        אשמח להצעות
                      </label>
                    </div>
                    <div className="signin-screen-custum-design-input-button-box universal-center-box-data">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginTop: 50 }}
                        onClick={() => {
                          if (singleId != null && singleId != "") {
                            dispatch({
                              type: SINGLE_LOOKING_GEN,
                              payload: lookingForGender,
                            });
                            window.location.href =
                              "select-image/?sid=" + singleId;
                          }
                        }}
                      >
                        ערוך פרופיל
                      </button>
                    </div>
                    <div>
                      <p
                        onClick={() => setShow(true)}
                        style={{
                          color: "#ee2c46",
                          marginTop: 30,
                          fontSize: 16,
                          fontWeight: 700,
                        }}
                      >
                        {"מחק פרופיל"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popup code start */}
      <Modal
        className="personal-area-modal-content-box"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="personal-area-modal-body-box">
          <Button
            className="close-cross-btn-personal-popup"
            onClick={handleClose}
          >
            <i className="bi bi-x"></i>
          </Button>
          <div className="text-box-personal-popup">
            <h2>האם למחוק את הפרופיל שלך? </h2>
            <p>לא ניתן לבטל את הפעולה!</p>
          </div>

          <div>
            <Button
              className="personal-Area-button-fill-box"
              onClick={handleClose}
            >
              בעצם לא
            </Button>
            <Button
              className="personal-Area-button-blank-box"
              onClick={yesDeleteIt}
            >
              כן, מחק פרופיל ⚠️
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* popup code end */}
    </>
  );
}
