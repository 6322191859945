import React, { useEffect } from "react";
import group from "../../../Common/Assets/Images/Group-last.png";
import Logo from "../../../Common/Assets/Images/logo.png";
import "../../../Common/CSS/Style.css";
import { useTranslation } from "react-i18next";
import topBg from "../../../Common/Assets/Images/top-bg.png";
import app from "../../../Firebase/Firebase";
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
export default function ProposalIrrelevant() {
  const { t } = useTranslation();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const firestore = getFirestore(app);

  const becomeMatcher = async () => {
    const result = await addDoc(collection(firestore, "invite"), {
      code: "1234",
      sender_id: "sp_" + linkId,
      create_date: serverTimestamp(),
    });

    const apiKey = "AIzaSyA1ReMg5OXdi3ufNAx8p9BhiQVIoyxCiXI";
    const domainUriPrefix = "https://matchers.page.link";
    const link = `${window.location.origin}/shareit/?id=${result.id}`;
    const androidPackageName = "com.app.matchers.dev";
    const iosBundleId = "com.matchers.app.dev";

    const res = await fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: domainUriPrefix,
            link: link,
            androidInfo: {
              androidPackageName: androidPackageName,
              androidFallbackLink: link,
            },
            iosInfo: {
              iosBundleId: iosBundleId,
              iosFallbackLink: link,
            },
          },
        }),
      }
    );

    const bodyData = await res.json();

    window.location.replace(bodyData.shortLink);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "visible";
  }, []);

  return (
    <>
      <div className="main-home">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row universal-row-box">
            <div className="col-12 matchup-col matchup2">
              <div className="logo-div">
                <img
                  className="ProposalIrrelevant-logo"
                  src={Logo}
                  alt="logo"
                />
              </div>

              <div className="h2-ProposalIrrelevant">
                <h2>
                  <span>{t("span-proposalIrrelevant")}</span>
                  <br />
                  {t("title-proposalIrrelevant")}
                </h2>
              </div>
              <div className="image-group2">
                <img
                  className="ProposalIrrelevant-image"
                  src={group}
                  alt="group1"
                />
              </div>

              <div className="para1-ProposalIrrelevant">
                <h4>{t("h4-proposalIrrelevant")} </h4>
                <p>{t("des-proposalIrrelevant")} </p>
              </div>

              <div className="ProposalIrrelevant-description1">
                {/* <p>{t("des2-proposalIrrelevant")}</p> */}
              </div>

              <div className="ProposalIrrelevant-button">
                <button
                  type="button"
                  className="ProposalIrrelevant-button-save"
                  // onClick={() => {
                  //   window.location.href = "https://play.google.com/store/apps";
                  // }}
                  onClick={becomeMatcher}
                >
                  {t("button-proposalIrrelevant")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
