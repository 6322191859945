import React, { useEffect, useState } from "react";
import Picker from "react-scrollable-picker";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HEIGHT_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

const SelectHeight = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [height, setHeight] = useState("1.50");
  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const firestore = getFirestore(app);

  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    valueGroups: {
      title: "Height",
    },
    // default value set
    optionGroups: {
      title: [{ value: "1.50", label: "1.50" }],
    },
  });

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    var _arr = [];
    for (let i = 2.0; i >= 1.0; i = i - 0.01) {
      _arr.push({ label: i.toFixed(2), value: i.toFixed(2) });
    }

    var dt = { ...data };
    dt.optionGroups = { title: _arr };

    setData(dt);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("overflow", "hidden", "important");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("overflow", "visible", "important");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));
    const heightData = docSnap.get("height");

    setHeight((heightData / 100).toFixed(2));

    var dt = { ...data };
    dt.optionGroups = {
      title: [
        {
          label: (heightData / 100).toFixed(2),
          value: (heightData / 100).toFixed(2),
        },
      ],
    };

    setData(dt);
    // setShow(false);
  };

  const handleChange = async (name, value) => {
    setHeight(value);

    var dt = { ...data };
    dt.valueGroups = {
      ...dt.valueGroups,
      [name]: value,
    };
    setData(dt);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    var height_option = parseFloat(height) * 100;

    dispatch({ type: HEIGHT_DATA, payload: height_option });

    console.log("height", height_option);

    if (sid != null && sid != "") {
      await updateDoc(doc(collection(firestore, "singles"), sid), {
        height: height_option,
        last_updated: serverTimestamp(),
      });
    }

    if (linkId != null && linkId != "") {
      Navigate(`/select-age?id=${linkId}`);
    } else {
      Navigate(`/select-age?sid=${sid}`);
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div className="main-home" style={{ height: window.innerHeight }}>
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine"
                  src={Logo}
                  alt=""
                />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text">
                  <b>{t("choose-page-title")}</b>
                </p>
              </div>

              <div>
                <Picker
                  optionGroups={data.optionGroups}
                  valueGroups={data.valueGroups}
                  onChange={handleChange}
                  height={300}
                  smooth={true}
                />
              </div>

              <div className="image-group1">
                <div className="button-div">
                  <button
                    className="button-accept profile-button"
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectHeight;
