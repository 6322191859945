import React, { useEffect } from "react";
import frame from "../../../Common/Assets/Images/matchup-image.png";
import Logo from "../../../Common/Assets/Images/logo.png";
import "../../../Common/CSS/Style.css";
import { useTranslation } from "react-i18next";
import topBg from "../../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../../Common/Assets/Images/middel-star-bg.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const lr_pid = new URLSearchParams(window.location.search).get("lrpid");
export default function Decline() {
  const { t } = useTranslation();
  const { match_reject } = useSelector((state) => state);

  const Navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "visible";

    if (match_reject) {
      Navigate(`/proposalIrrelevant?lrpid=${lr_pid}`);
    }

    window.addEventListener("popstate", e => {
      window.location.replace(`/?lrpid=${lr_pid}`);
    });

  }, []);



  return (
    <>
      <div className="main-home">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row universal-row-box">
            <div className="col-12 matchup-col matchup2">
              <div className="logo-div">
                <img
                  className="decline-logo universal-logo"
                  src={Logo}
                  alt="logo"
                />
              </div>
              <div className="universal-background-middel-star select-detail-star-background">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>

              <div className="image-group1">
                <img className="decline-image" src={frame} alt="group1" />
              </div>

              <div className="h2-decline">
                <h2>{t("title-decline")}</h2>
              </div>

              <div className="decline-description">
                <p>{t("description-decline")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom select-detail-universal-bac-bottum">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
}
