import React from "react";
import { useTranslation } from "react-i18next";
import heartpop from "../Common/Assets/Images/dummyProfile.png";
import heartbg from "../Common/Assets/Images/bg.png";


import HeartPopup2 from "./HeartPopup2";

function HeartPopup({matcherData, csid}) {
  const { t } = useTranslation();
  // console.log(matcherData)
  return (
    <>
      <div className="popup-3">
        <div
          className="modal heart-popup-3 popup fade"
          id="exampleModal3"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog heartmodal-dialog ">
            <div className="modal-content heart-popup-modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  // onClick={() => dispatch({ type: CLEAR_STATES })}
                  className="btn-close remove-btn-close-cross"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg"></i>
                </button>
                <div className="main-img-heartpopup">
                  <img
                    src={matcherData.matcherImage ? matcherData.matcherImage : heartpop}
                    className="d-block main-home-profile-img-slide"
                    alt="img-1"
                  />
                </div>
                <h2 className="heart-popup-heading-text">
                  {t("title-web-page-popup")}
                </h2>
                <p className="heart-popup-pehra-text">
                  {t("title-web-page-description").replace("<mxxxx>",matcherData.matcherName ? matcherData.matcherName : " ")}
                </p>
                <div className="heart-popup-button-box">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-target="#exampleModal4"
                  >
                    {t("title-web-page-btn1")}
                  </button>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    className="btn btn-primary button-blank-box exit-popup"
                    aria-label="Close"
                  >
                    {t("title-web-page-btn2")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HeartPopup2 csid={csid}  />
    </>
  );
}

export default HeartPopup;
