import React, { useEffect, useRef, useState } from "react";
import Group1 from "../../Common/Assets/Images/Group1.png";
import Logo from "../../Common/Assets/Images/logo.png";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import app from "../../Firebase/Firebase";
import { POP_UP, SAVE_TERMS, SELECT_IMAGE } from "../../Redux/constants";
import { useTranslation } from "react-i18next";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { Button, Modal } from "react-bootstrap";

export default function SelectDetails() {
  const firestore = getFirestore(app);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const linkId = new URLSearchParams(window.location.search).get("id");
  const [show, setShow] = useState(false);
  const [fillData, setFillData] = useState(false);
  const [alreadyFillData, setAlreadyFillData] = useState(false);

  //  get data from dispatch
  const {
    signle_documentid,
    singles_docid,
    matcher_id,
    associated_profiles,
  } = useSelector((state) => state);
  const fileRef = useRef();

  // if prifill is data then show alert and if prifill data is not exists then data update

  const PrefillDataSave = async () => {
    // setShow for loading function
    setShow(true);

    var associated_matchers = [];
    associated_profiles.forEach((profile) => {
      associated_matchers.push(profile.matcher_id);
    });

    // if data is allready fill from matcher then show modal (setAlreadyFillData(true));

    if (signle_documentid != null && signle_documentid != "") {
      setShow(false);
      setAlreadyFillData(true);
    } else {
      // if singles document id is not null then update doc id in single_profiles
      if (singles_docid != null && singles_docid != "") {
        await updateDoc(doc(collection(firestore, "single_profiles"), linkId), {
          single_id: singles_docid,
        });

        // and update singles collection and update associated_profiles keys

        await updateDoc(doc(collection(firestore, "singles"), singles_docid), {
          is_active: true,
          associated_profiles: [
            ...associated_profiles,
            { id: linkId, approved: true, matcher_id: matcher_id },
          ],
          associated_matchers: [...associated_matchers, matcher_id],
        });

        // update accepted_eula_date key in single

        // var approved = 0;
        // if (save_terms !== null && save_terms !== "") {
        //   approved = 1;
        //   await updateDoc(
        //     doc(collection(firestore, "singles"), singles_docid),
        //     {
        //       accepted_eula_date: save_terms,
        //     }
        //   );
        // }

        const documentSnap = await getDoc(
          doc(firestore, "matchers", matcher_id)
        );

        var data = documentSnap.exists()
          ? documentSnap.get("represented_singles") != null
            ? documentSnap.get("represented_singles")
            : []
          : [];

        const representedSingles = data.map((item) => {
          if (item.id === linkId) {
            return { ...item, single_id: singles_docid, approved: 1 };
          }
          return item;
        });

        //  and update represented_singles key into matchers collection
        await updateDoc(doc(collection(firestore, "matchers"), matcher_id), {
          represented_singles: representedSingles,
        });

        // send to popup for splash page
        dispatch({ type: POP_UP });
        setShow(false);
        Navigate(`/splash?id=${linkId}`);
      } else {
        setShow(false);
        setFillData(true);
      }
    }

    dispatch({
      type: SAVE_TERMS,
      payload: null,
    });
  };

  // show popup on if data already use

  const btnGetImage = async () => {
    setShow(true);

    if (signle_documentid != null && signle_documentid != "") {
      setShow(false);
      setAlreadyFillData(true);
    } else {
      setShow(false);
      if (fileRef.current.click()) {
        Navigate(`/crop-file?id=${linkId}`);
      }
    }
  };

  // if save_terms is null then navigate on home page

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //this function use to read the image file and convert the base64 file

  const handleChange = (e) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      const { current } = fileRef;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;

        // use redux for send image on next page

        dispatch({
          type: SELECT_IMAGE,
          payload: e.target.result,
        });
      };
      reader.readAsDataURL(file);

      //submit image on after one second

      setTimeout(() => {
        Navigate(`/crop-file?id=${linkId}`);
      }, 1000);
    }
  };

  return (
    <>
      {/* loading model */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="main-home ">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row universal-row-box select-detail-universal-row-box">
            <div className="col-12 matchup-col matchup2">
              <div className="logo-div">
                <img className="universal-logo " src={Logo} alt="logo" />
              </div>
              <div className="universal-background-middel-star select-detail-star-background">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div">
                <p className="text-1 paragraph-text chcked-detail-top-title">
                  {t("SelectDetails-page-title")}
                </p>
              </div>
              <div className="image-group1">
                <img
                  className="chcked-detail-main-img"
                  src={Group1}
                  alt="group1"
                />
              </div>

              <div className="button-div">
                {singles_docid != null && singles_docid != "" && (
                  <button
                    className="button-accept"
                    onClick={() => {
                      PrefillDataSave();
                    }}
                  >
                    {t("SelectDetails-page-button1")}
                  </button>
                )}

                <button
                  onClick={btnGetImage}
                  className="button-accept button-blank-box select-detail-button-blank-box"
                >
                  {t("SelectDetails-page-button2")}
                </button>

                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom select-detail-universal-bac-bottum">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>

      {/* please fill data popup this side*/}

      <Modal show={fillData} className="popop-fill-data">
        <div className="Filling-content">
          <h5>{t("title-fill-in-therequired")}</h5>
        </div>

        <div className="Fill-data-button">
          <Button onClick={() => setFillData(false)}>OK</Button>
        </div>
      </Modal>

      {/* You have already submitted this popup */}

      <Modal show={alreadyFillData} className="popop-fill-data">
        <div className="Filling-content">
          <h5> {t("title-already submitted")}</h5>
        </div>

        <div className="Fill-data-button">
          <Button onClick={() => Navigate(`/splash?id=${linkId}`)}>OK</Button>
        </div>
      </Modal>
    </>
  );
}
