export const S_NAME = "S_NAME";
export const M_NAME = "M_NAME";
export const SET_IMAGE = "SET_IMAGE";
export const SELECT_INDEX = "SELECT_INDEX";
export const CLOSE_BUTTON = "CLOSE_BUTTON";
export const CLEAR_STATES = "CLEAR_STATES";
export const SELECT_IMAGE = "SELECT_IMAGE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const USER_ID = "USER_ID";
export const CROP_IMAGE = "CROP_IMAGE";
export const POP_UP = "POP_UP";
export const SELECT_POP = "SELECT_POP";
export const PREFILL_DATA = "PREFILL_DATA";
export const PERSONANLITY_DATA = "PERSONANLITY_DATA";
export const REJECTRESON_DATA = "REJECTRESON_DATA";
export const HEIGHT_DATA = "HEIGHT_DATA";
export const SINGLE_GEN = "SINGLE_GEN";
export const SINGLE_LOOKING_GEN = "SINGLE_LOOKING_GEN";
export const AGE_DATA = "AGE_DATA";
export const LOCATION_DATA = "LOCATION_DATA";
export const OCCUPATION_DATA = "OCCUPATION_DATA";
export const LIFESTYLE_DATA = "LIFESTYLE_DATA";
export const POLITICS_DATA = "POLITICS_DATA";
export const AGE_RANGE_DATA = "AGE_RANGE_DATA";
export const LIFESTYLE_LOOKING_DATA = "LIFESTYLE_LOOKING_DATA";
export const GEOGRAPHY_DATA = "GEOGRAPHY_DATA";
export const USER_GENDER = "USER_GENDER";
export const IMAGE_DATA = "IMAGE_DATA";
export const MOBLIE_NUMBER = "MOBLIE_NUMBER";
export const TERMS_POPUP = "TERMS_POPUP";
export const SAVE_TERMS = "SAVE_TERMS";
export const SINGLE_DOCID = "SINGLE_DOCID";
export const SINGLES_ID = "SINGLES_ID";
export const MATCHER_ID = "MATCHER_ID";
export const ASSOCIATED_PROFILES = "ASSOCIATED_PROFILES";
export const LRPID = "LRPID";
export const MATCH_REJECT = "MATCH_REJECT";
export const LHS_KEY = "LHS_KEY";
export const RHS_KEY = "RHS_KEY";
export const TIMESTEMP = "TIMESTEMP";
export const LCS_STATE = "LCS_STATE";
export const RCS_STATE = "RCS_STATE";
export const phoneNumberToBeVerify = "phoneNumberToBeVerify";
export const otpConfirmationResult = "otpConfirmationResult";
export const spid = "spid";
export const lrmpid = "lrmpid";
