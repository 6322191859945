import React, { useEffect, useState } from "react";
import Group1 from "../../Common/Assets/Images/Group1.png";
import Logo from "../../Common/Assets/Images/logo.png";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import terms from "../../Common/Assets/Images/terms.png";
import termsCheck from "../../Common/Assets/Images/termscheck.png";
import termIcon from "../../Common/Assets/Images/termCheck.png";
import { SAVE_TERMS, SELECT_IMAGE } from "../../Redux/constants";
import { serverTimestamp } from "firebase/firestore";
import { useRef } from "react";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { Modal } from "react-bootstrap";

// export default function UploadUserGallery() {
const UploadUserGallery = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [termPopup1, setTermPopup1] = useState(false);

  const linkId = new URLSearchParams(window.location.search).get("id");

  // on click check icon show and hide
  const { singles_docid } = useSelector((state) => state);

  const fileRef = useRef();

  useEffect(() => {
    if (singles_docid == null || singles_docid == "") {
      setTermPopup1(true);
    }

    window.scrollTo(0, 0);

    // window.addEventListener("popstate", e => {
    //   window.location.replace(`/?id=${linkId}`);
    // });
  }, []);

  const btnGetImage = async () => {
    fileRef.current.click();
  };

  const handleChange = async (e) => {
    setLoading(true);

    var file = e.target.files[0];

    if (file) {
      if (file.type.toString().includes("heic")) {
        file = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 1,
        });
      }

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        dispatch({
          type: SELECT_IMAGE,
          payload: e.target.result,
        });

        Navigate(`/crop-file?id=${linkId}`);
      };

      reader.readAsDataURL(compressedFile);
    }

    setLoading(false);
  };

  return (
    <>
      {/* loading modle */}
      <Modal show={isLoading} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>
      <div className="main-home">
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container  first-container">
          <div className="row universal-row-box">
            <div className="col-12 matchup-col matchup2">
              <div className="logo-div">
                <img className="universal-logo" src={Logo} alt="logo" />
              </div>
              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>

              <div className="logo-div">
                <p className="text-1 paragraph-text pehragraph-upload-gallery">
                  {t("upload-page-title")}
                </p>
              </div>
              <div className="image-group1">
                <img
                  className="upload-gallery-main-img"
                  src={Group1}
                  alt="group1"
                />
              </div>
              <div>
                <ul className="list-user-gallery1">
                  <li key={"wqewq1"}> {t("upload-page-details1")}</li>
                  <li key={"xcws2"}>{t("upload-page-details2")}</li>
                  <li key={"erqrfwe3"}> {t("upload-page-details3")}</li>
                  <li key={"erwfc4"}>{t("upload-page-details4")}</li>
                </ul>
              </div>

              <div className="button-div button-box-upload-gallery">
                <button
                  className="button-accept"
                  //Navigate to next page (/check-details) on this click
                  onClick={() => {
                    if (linkId) {
                      if (singles_docid == null || singles_docid == "") {
                        btnGetImage();
                      } else {
                        Navigate(`/check-details?id=${linkId}`);
                      }
                    }
                  }}
                >
                  {t("upload-page-button")}
                </button>

                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  hidden
                />
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>

      {/* terms privacy policy check modal */}

      {termPopup1 ? (
        <div className="popup-3">
          <div className="popup-good popup-existing-profile "></div>
          <div className="modal d-block splash-auto-open-popup">
            <div className="modal-dialog splash-modal">
              <div className="modal-content splash-modal-content">
                <div className="modal-body">
                  <div className="modal-header term-icon">
                    <img src={termIcon} alt="" className="termsIcon" />
                  </div>
                  <h2 className="popup-title-top">
                    מחויבים לפרטיות <br />
                    שלכם
                  </h2>
                  <div className="terms_popup">
                    <p className="term-popup-pehra-box">
                      אני מסכים ל
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href={"/terms"}
                      >
                        <b>
                          <span> תנאי השימוש </span>
                        </b>
                        <br />
                        <b>
                          <span>ומדיניות הפרטיות </span>
                        </b>
                      </a>
                      של השירות
                    </p>
                    <img
                      src={show ? termsCheck : terms}
                      alt=""
                      className="terms-check"
                      onClick={() => {
                        setShow(!show);
                      }}
                    />
                  </div>

                  <button
                    style={{ backgroundColor: show ? "#EE2C46" : "#B3B3B3" }}
                    onClick={() => {
                      if (show) {
                        setTermPopup1(false);
                        dispatch({
                          type: SAVE_TERMS,
                          payload: serverTimestamp(),
                        });
                      }
                    }}
                    type="button"
                    className="btn btn-primary btn-good terms-check"
                  >
                    {t("popup1-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default UploadUserGallery;
