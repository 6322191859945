import React, { useEffect, useState } from "react";
import Logo from "../../Common/Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import topBg from "../../Common/Assets/Images/top-bg.png";
import BottumBg from "../../Common/Assets/Images/bottum-bg.png";
import middelBg from "../../Common/Assets/Images/middel-star-bg.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GEOGRAPHY_DATA } from "../../Redux/constants";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import app from "../../Firebase/Firebase";
import { Modal } from "react-bootstrap";

const SelectGeography = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const linkId = new URLSearchParams(window.location.search).get("id");
  const sid = new URLSearchParams(window.location.search).get("sid");

  const [geography_options, setGeographyOptions] = useState([]);

  const [show, setShow] = useState(false);

  const firestore = getFirestore(app);


  const geographyData = [
    { english: "Tel-Aviv/Center", hebrew: "תל-אביב/מרכז" },
    { english: "Jerusalem and surroundings", hebrew: "ירושלים והסביבה" },
    { english: "North", hebrew: "צפון" },
    { english: "South", hebrew: "דרום" },
  ];

  // show height counting on ui in this side

  useEffect(() => {
    window.scrollTo(0, 100);

    if (sid != null && sid != "") {
      getData();
    }

    document.body.style.setProperty("", "", "");
    document.body.style.overscrollBehaviorY = "contain";

    return () => {
      document.body.style.setProperty("", "", "");
      document.body.style.overscrollBehaviorY = "none";
    };
  }, []);

  const getData = async () => {
    // setShow(true);
    const docSnap = await getDoc(doc(firestore, "singles", sid));

    if (docSnap.exists) {
      const geography = docSnap.get("geography");

      if (geography != null) {
        setGeographyOptions(geography);
      }
    }
    // setShow(false);
  };

  // Save data in firebase cloud this side

  const saveData = async () => {
    console.log("geography_options", geography_options);

    if (geography_options.length > 0) {
      dispatch({
        type: GEOGRAPHY_DATA,
        payload: geography_options,
      });

      if (sid != null && sid != "") {
        await updateDoc(doc(collection(firestore, "singles"), sid), {
          geography: geography_options,
          last_updated: serverTimestamp(),
        });
      }

      if (linkId != null && linkId != "") {
        Navigate(`/looking-personality-traits?id=${linkId}`);
      } else {
        Navigate(`/looking-personality-traits?sid=${sid}`);
      }
    }
  };

  return (
    <>
      {/* loading page */}
      <Modal show={show} className="model-loader">
        <div className="modal-dialog loading-page">
          <div className="modal-content loading-page">
            <div className="loader1 save-loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="main-home location occupation life_style second"
        style={{ height: window.innerHeight }}
      >
        <div className="universal-background-top">
          <img
            className="universal-background-top-img"
            src={topBg}
            alt="logo"
          />
        </div>
        <div className="container first-container">
          <div className="row">
            <div className="col-12 matchup-col matchup-col-select-height">
              <div className="logo-div">
                <img
                  className="universal-logo logo-margine location"
                  src={Logo}
                  alt=""
                />
              </div>

              <div className="line-indicator-box container-fluid">
                <div className="row">
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-fill"></div>
                  <div className="col-1 indicator-blank"></div>
                </div>
              </div>
              <div className="universal-background-middel-star">
                <img
                  className="universal-background-middel-star-img"
                  src={middelBg}
                  alt="logo"
                />
              </div>
              <div className="logo-div ">
                <p className="text-1 gender-1-text location lifestyle">
                  <b>{"עד לאן שווה לך להגיע בשביל דייט מעולה?"}</b>
                </p>
              </div>

              <div class="style-content">
                <p>{"אפשר לבחור כמה"}</p>
              </div>

              <ul className="list-btn-data-box life-style">
                {geographyData.map((item, index) => {
                  return (
                    <li
                      key={index}
                      id="list_item"
                      onClick={() => {
                        var dt = [...geography_options];
                        const ind = dt.indexOf(index);
                        if (ind > -1) {
                          dt.splice(ind, 1);
                        } else {
                          dt.push(index);
                        }
                        setGeographyOptions(dt);
                      }}
                      style={{
                        background:
                          geography_options.indexOf(index) > -1
                            ? "#EE2C46"
                            : "white",
                        color:
                          geography_options.indexOf(index) > -1
                            ? "white"
                            : "#9A9A9A",
                        border:
                          geography_options.indexOf(index) > -1
                            ? "1px solid #EE2C46"
                            : "1px solid #D9D9D9",
                      }}
                    >
                      <a>{item["hebrew"]}</a>
                    </li>
                  );
                })}
              </ul>

              <section class="location">
                <div class="container">
                  <div location-wrap></div>
                </div>
              </section>

              <div className="image-group1 location-btn">
                <div className="button-div btn-change">
                  <button
                    className="button-accept profile-button"
                    style={
                      geography_options.length > 0
                        ? { backgroundColor: "#EE2C46" }
                        : { backgroundColor: "grey" }
                    }
                    onClick={saveData}
                  >
                    {t("choose-page-button")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="universal-background-bottom">
          <img
            className="universal-background-bottom-img"
            src={BottumBg}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};
export default SelectGeography;
