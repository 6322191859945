const resources = {
  en: {
    translation: {
      title: "Title!",
    },
  },
  he: {
    translation: {
      // title: "הי <xxxxx>, חדשות טובות!", // old
      title: "הי <xxxxx>, חדשות טובות!",

      // description: "יובל מעוניין למצוא לך דייט והוא", // old
      // "des-span": "matchup",
      // "des-1": "רוצה להוסיף אותך ב-",
      // "des-bold": "פלטפורמה שבה חברים משדכים חברים",

      "des-span": "matchers",
      "des-1": " רוצה למצוא לך דייט ולהוסיף אותך ב- ",
      "des-bold": "פלטפורמה שבה חברים משדכים חברים",

      "description": "<mxxxx> רוצה למצוא לך דייט",
      "description-2": " ולהוסיף אותך ב- ",
      "description-2-red": "matchers",
      "description-3": "פלטפורמה שבה חברים",
      "description-4": "משדכים חברים",
      
      "sub-heading": "איך זה עובד? ",

      "1-para-1": "חברים יספרו עליך לאלגוריתם",
      "1-para-2": "שלנו",

      "2-para-1": "האלגוריתם שלנו יחפש לך ",
      "2-para-2": "התאמות לפי המון פרמטרים",
      "2-para-3": "וקשרים חברתיים",

      "3-para-1": "כשתהיה לאלגוריתם הצעה - ",
      "3-para-2": "חברים שלך יבדקו אותה ויעבירו",
      "3-para-3": "אליך",

      "home-title-2": "אז שנפרגן ל<mxxxx>",
      "home-title-2-1": "למצוא לך דייט?",

      "home-page-btn": "קדימה להירשם 👍",
      "home-page-btn-2": "אני לא רוצה 👎",

      // next title

      title1: "חברים יספרו עליך לאלגוריתם שלנו",
      title2: "האלגוריתם שלנו יחפש לך התאמות לפי המון פרמטרים וקשרים חברתיים",

      // title3: "כשיהיה לאלגוריתם הצעה - יובל יבדוק אותה ויעביר אליך", // old
      // "question-title":
      //   "והכי חשוב! המידע שלך נשמר בפרטיות ואף אחד לא יכול לחפש את הפרופיל שלך או לעשות לו סוויפ",

      // title3 : " כשתהיה לאלגוריתם הצעה - חברים שלך יבדקו אותה ויעבירו אליך", // old
      // "question-title":
      //   "והכי חשוב! המידע שלך נשמר בפרטיות ואף אחד לא יכול לחפש את הפרופיל שלך או לעשות לו סוויפ",

      title3: "כשתהיה לאלגוריתם הצעה - חברים שלך יבדקו אותה ויעבירו אליך",
      "question-title-top": "והכי חשוב!",
      "question-title":
        " המידע שלך נשמר בפרטיות ואף אחד לא יכול לחפש את הפרופיל שלך או לעשות לו סוויפ",

      // title4: "אז שנפרגן ליובל למצוא לך דייט?", // old

      // title4: "אז שנפרגן ל <mxxxx> למצוא לך דייט?", // old
      // "button-home": "קדימה",

      title4: "אז שנפרגן ל<mxxxx> למצוא לך דייט?",
      "button-home": "קדימה",

      link: "לתנאי השימוש",
      "des-link": "הרשמה מהווה הסכמה ",
      button2: "נוותר הפעם",
      "ques-title-last": "מה זה Matchers?",

      // Popup-file 1

      // "popup-title": "מחויבים לפרטיות שלכם",
      // "popup_des_ui": "השימוש ומדיניות",
      // "popup_des_ui1":"תנאי השימוש",
      // "popup-des":
      //   "אני מסכים לתנאי השימוש ומדיניות הפרטיות של השירות",
      "popup1-button": "המשך",

      // Popup-file 2

      "popup2-title": "אין בעיה, תמיד אפשר להתחרט",

      // UploadUserGallery page

      "upload-page-title": "נתחיל עם תמונת פרופיל",
      "upload-page-details1": " טיפ מהיר:",
      "upload-page-details2": "✅ סלפי זה מעולה 🤳",
      "upload-page-details3": "🚫 בלי תמונות קבוצתיות 👩👧",
      "upload-page-details4": "🚫 בלי משקפי שמש 🕶",
      "upload-page-button": "המשך",
      //SelectDetails page
      "SelectDetails-page-title": "נתחיל עם תמונת פרופיל",
      "SelectDetails-page-button1": "השתמש בפרופיל הקיים",
      "SelectDetails-page-button2": "בחר תמונה חדשה",

      //CropFile package

      "crop-page-title": " אתם נראים הכי טוב כמו שאתם",
      "crop-page-des": "חתכו את התמונה כך שתתאים לריבוע האדום",
      "crop-page-button": "בול בפוני",

      //select-image page save photo

      "select-image-title": "התמונות שלך",
      "select-image-details1": " טיפ מהיר:",
      "select-image-details2": "✅ סלפי זה מעולה 🤳",
      "select-image-details3": "🚫 בלי תמונות קבוצתיות 👩👧 ",
      "select-image-details4": "🚫 בלי משקפי שמש 🕶",
      "select-image-button": "שמור והמשך",

      //choose-height - page

      "choose-page-title": "מה הגובה שלך?",
      "choose-page-button": "שמור והמשך",

      //UserGender-page

      "usergander-page-title": "מה את/ה מחפש/ת?",
      female: "אישה",
      male: "גבר",
      "usergender-page-button": "שמור והמשך",

      // select-option-page

      "select-option-title": "איזה טיפוס נחפש לך?",
      "select-option-paragraph": "אפשר לבחור עד 5 תכונות",
      "select-option1": "מורכב",
      "select-option2": "מורכב",
      "select-option3": "מורכב",
      "select-option4": "מורכב",
      "select-option5": "מורכב",
      "select-option6": "מורכב",
      "select-option7": "מורכב",
      "select-option8": "מורכב",
      "select-option9": "מורכב",
      "select-option10": "מורכב",
      "select-option11": "מורכב",
      "select-option12": "מורכב",
      "select-option-button": "שמור והמשך",

      // Reject PopUp
      "popup-reject-proposal-title": "מה לא התאים?",
      "popup-reject-proposal-subtitle": "אפשר לבחור יותר מסיבה אחת",
      "popup-reject-proposal-input": "הקלד סיבה אחרת... ",
      "popup-reject-proposal-btn": "שלח",
      "popup-reject-proposal-bottom-text": "אל דאגה, זה יישאר בינינו",


      //Popup3 for submit details

      "popup-submit-title": "סחטיין!",
      "popup-submit-des": "עשית את שלך, עכשיו החברים ואנחנו מתחילים לעבוד...",
      "popup-submit-des-subtitle1": "הידעת?",
      "popup-submit-des-subtitle2": "אפשר ואף כדאי שכמה חברים יחפשו לך דייט ב-Matchers 😲",
      "popup-submit-button": "סבבה",
      "popup-submit-button-new": "סבבה לגמרי",

      //splash - page

      "splash-page-title": "מסדרים דייט לחברים",
      "splash-page-button1": "גם אני רוצה לשדך",
      "splash-page-button2": "לאזור האישי",

      //single-profile-page

      // "title-web": " דייט עם מיכל? ", // old
      "title-web": "דייט עם <xxxxx>?",
      "title-web2": "אחללה!",
      // "para-web": "עכשיו מחכים לאור ירוק ממיכל", // old
      "para-web": "עכשיו מחכים לאור ירוק מ<xxxxx>",
      "image-title": "מיכל פוקס",
      "image-title1": "29, ירושלים",
      "image-title2": "מנהל חשבונות",
      "image-title3": "דתי ליברלי",
      "image-btn": "חברותית",
      "image-btn1": "משפחתית",
      "image-btn2": "עקשנית",
      "play-button": "חברים מספרים",
      "exit-btn": "פחות מתאים",
      "like-btn": "יאללה דייט",

      // heart-pop-web

      "title-web-page-popup": "בלי חרטות? ",
      // "title-web-page-description": "יובל חשב/ה שזה מתאים...", // old
      "title-web-page-description": "<mxxxx> חשב/ה שזה מתאים...",
      "title-web-page-btn1": "כן, בטוח",
      "title-web-page-btn2": "בעצם, שווה בדיקה",

      //? heart popup -web 2

      "title-web-page-popup2": "אפשר לשאול למה?",
      "title-web-page2-description": "לא מתאים",
      "title-web-page2-btn1": "אני תפוס/ה",
      "title-web-page2-btn2": "שלח",

      // You have already submitted this popup

      "title-already submitted": "כבר הגשת",

      // Please fill in the required information

      "title-fill-in-therequired": "נא למלא את המידע הנדרש",

      // Excellent, we used the existing profile popup

      "title-existing profile": "מעולה, השתמשנו בפרופיל הקיים",
      "title_become_matcher": "רוצה גם לשדך את חברים שלך?",
      'btn_become_matcher':'בפעם אחרת... ',
      'title_become_matcher_1': 'ומי יסדר לחברים שלך דייט? ',
      'title_become_matcher_2':' הצטרף עכשיו כ-Matcher ',

      //decline page

      "title-decline": "אוקיי",
      "description-decline": "נמשיך לחפש ולהעביר הצעות דרך חברים שלך",

      // matchup-success-page
      // "title-success-page": "רן אמר כן!", // old
      "title-success-page": "<sxxxx> בעניין!",
      "des-success-page": "שיהיה דייט מוצלח:-)",
      "image-title-success-page": "רן כהן",
      "save-success-page": "שמירת איש קשר",
      "btn-success-page": "גם אני רוצה לשדך!",

      "match-success-title": "זהו, זה נגמר בינינו",
      "match-success-text-1": "שינינו עכשיו את הסטטוס שלך",
      "match-success-text-2" : "ל״תפוס/ה״",
      "match-success-text-3-bold" : " והפסקנו לחפש לך הצעות 🏖️",
      "match-success-text-4" : "כדי שנחזור לחפש לך הצעות - יש לעדכן סטטוס באזור האישי באתר שלנו. מקווים שלא יהיה",
      "match-success-text-5" :  "בזה צורך 🤞",
      
      "match-success-btn-text" : "אחלה הבנתי",

      // proposalIrrelevant page
      "span-proposalIrrelevant": "אופס",
      "title-proposalIrrelevant": "ההצעה כבר לא רלוונטית",
      "h4-proposalIrrelevant": "הצעות יכולות להיעלם מכמה סיבות",
      "des-proposalIrrelevant":
        "למשל, כי אחד הצדדים תפוס, כי ההצעה פחות התאימה לו, או כי הוא לא הגיב בזמן",
      "des2-proposalIrrelevant": "אולי נזמין חברים נוספים לשדך אותך?",
      "button-proposalIrrelevant": "גם אני רוצה לשדך!",

      // terms privacy policy

      "title-terms": "",
      "heading-terms": ".דבכמו חוטב ,יתרבח בחרמל םיבויחמ",
      "des-terms": "",
      "des1-terms": "",
      "des2-terms": "",
      "des3-terms": "",
      "button-terms": "",



      "malefemale-heading": "רק לוודא שהבנת 😇",
      "malefemale-text": "שאלנו האם לחפש לך גברים או נשים, ולא מה המגדר שלך",
      "malefemale-btn-1": "הבנתי, נמשיך",
      "malefemale-btn-2": "אה, צריך לתקן",

      // home
    },
  },
};
export default resources;
